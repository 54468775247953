<template>
  <svg
    viewBox="0 0 16 16"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <path
      d="M11.742 10.344a6.5 6.5 0 10-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 001.415-1.414l-3.85-3.85a1.007 1.007 0 00-.115-.1zM12 6.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0z"
      fill-rule="evenodd"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import useIconSetUp from './useIconSetUp';

const props = withDefaults(
  defineProps<{
    iconName: string;
    iconColor?: string;
    fontSize?: string;
    width: string | number;
    height: string | number;
  }>(),
  {
    iconName: 'IconDefault',
    iconColor: 'currentColor',
    fontSize: '1rem',
    width: 16,
    height: 16,
  },
);

const { widthValue, heightValue, viewBoxProp, iconStyle } = useIconSetUp(props);
</script>
