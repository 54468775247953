<template lang="pug">
  span.wrap {{ currentText }}
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

interface Props {
  texts: string[];
  typingSpeed?: number;
  deleteSpeed?: number;
  pauseTime?: number;
}

const props = defineProps<Props>();
const currentText = ref('');
const loopNum = ref(0);
const isDeleting = ref(false);

const typingSpeed = props.typingSpeed || 400; // milliseconds
const deleteSpeed = props.deleteSpeed || 200; // milliseconds
const pauseTime = props.pauseTime || 3000; // milliseconds

const typeEffect = () => {
  const currentIndex = loopNum.value % props.texts.length;
  const fullText = props.texts[currentIndex];

  if (isDeleting.value) {
    currentText.value = fullText.substring(0, currentText.value.length - 1);
  } else {
    currentText.value = fullText.substring(0, currentText.value.length + 1);
  }

  let delay = typingSpeed;
  if (isDeleting.value) delay = deleteSpeed;

  if (!isDeleting.value && currentText.value === fullText) {
    delay = pauseTime;
    isDeleting.value = true;
  } else if (isDeleting.value && currentText.value === '') {
    isDeleting.value = false;
    loopNum.value++;
    delay = 500;
  }

  setTimeout(typeEffect, delay);
};

onMounted(() => {
  typeEffect();
});
</script>

<style scoped>
.wrap {
  border-right: 0.08em solid #6a0dad;
}
</style>
