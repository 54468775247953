<template lang="pug">
.loader(
  :class="[{ 'loader--fullPage': isFullPage }, { 'loader--background': isClear }, { 'loader--backgroundWhite': hasBackground }]"
  :aria-busy="true"
)
  .loader__content
    .sk-fading-circle(:class="{ 'clear': isClear }")
      slot
        span(v-for="n in 12" :key="n" :class="`sk-circle sk-circle${n}`")
</template>

<script>
export default {
  name: 'SpinnerLoader',
  props: {
    isClear: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#333', // Default color for the loader
    },
  },
};
</script>

<style scoped lang="scss">
/* Root loader styles */
.loader.loader--fullPage {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}

.loader.loader--background {
  background: rgba(0, 0, 0, 0.8);
}

.loader.loader--backgroundWhite {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: saturate(180%) blur(10px);
}

.loader {
  position: relative;
  width: 100%;
  height: 100%;

  .loader__content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
  }
}

/* Loader animation */
.sk-fading-circle {
  position: relative;
  width: 40px;
  aspect-ratio: 1 / 1;

  .sk-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      display: block;
      width: 15%;
      height: 15%;
      margin: 0 auto;
      border-radius: 100%;
      background-color: var(--loader-color, v-bind('color'));
      animation: sk-circle-fade-delay 1.2s infinite ease-in-out both;
    }
  }

  &.clear {
    .sk-circle {
      &::before {
        background-color: #fff;
      }
    }
  }

  .sk-circle2 {
    transform: rotate(30deg);
  }

  .sk-circle3 {
    transform: rotate(60deg);
  }

  .sk-circle4 {
    transform: rotate(90deg);
  }

  .sk-circle5 {
    transform: rotate(120deg);
  }

  .sk-circle6 {
    transform: rotate(150deg);
  }

  .sk-circle7 {
    transform: rotate(180deg);
  }

  .sk-circle8 {
    transform: rotate(210deg);
  }

  .sk-circle9 {
    transform: rotate(240deg);
  }

  .sk-circle10 {
    transform: rotate(270deg);
  }

  .sk-circle11 {
    transform: rotate(300deg);
  }

  .sk-circle12 {
    transform: rotate(330deg);
  }

  .sk-circle2::before {
    animation-delay: -1.1s;
  }

  .sk-circle3::before {
    animation-delay: -1s;
  }

  .sk-circle4::before {
    animation-delay: -0.9s;
  }

  .sk-circle5::before {
    animation-delay: -0.8s;
  }

  .sk-circle6::before {
    animation-delay: -0.7s;
  }

  .sk-circle7::before {
    animation-delay: -0.6s;
  }

  .sk-circle8::before {
    animation-delay: -0.5s;
  }

  .sk-circle9::before {
    animation-delay: -0.4s;
  }

  .sk-circle10::before {
    animation-delay: -0.3s;
  }

  .sk-circle11::before {
    animation-delay: -0.2s;
  }

  .sk-circle12::before {
    animation-delay: -0.1s;
  }
}
@keyframes sk-circle-fade-delay {
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
@keyframes sk-circle-fade-delay {
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
</style>
