<template lang="pug">
section.my-5.py-5
  #meet.scrollIntoView.gradient__grid
    .gradient__top
      EcreativMoleculesTitleSection.mb-5(
        title="Meet"
        subtitle="Connect with Local Imams for Guidance and Support"
      )
    .gradient__main
      .bodyMeet.d-flex.gap-3
        .map
          .map-box
            //- EcreativAtomsSvgIcon(iconName='ukMap' :isCustom='true', iconSize="full")
            img(src="/images/ukMap.svg", alt="Map")
    .gradient__sidebar
      EcreativMoleculesMeetSideBar
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
    required: true,
  },
});

const videos = [
  {
    id: 1,
    image: '/images/home-3.jpg',
    title: 'The Importance of Bait in Islam',
    time: '47:15',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
];

const tabs = ref([
  { label: 'Featured' },
  { label: 'Latest' },
  { label: 'Most Viewed' },
]);

const selected = ref('Featured');

const setSelected = (tab) => {
  selected.value = tab;
};
</script>

<style lang="scss" scoped>
.bodyMeet {
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.map {
  flex: 1;
}

.map-box {
  max-height: 80%;
}

.gradient__grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 0px 1fr 0px;
  grid-template-areas:
    '. top .'
    '. main .'
    '. sidebar .';

  @include media-breakpoint-up(sm) {
    grid-template-columns: calc(50vw - 17.1rem) 1fr calc(50vw - 17.1rem);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: calc(50vw - 22rem) 1fr calc(50vw - 22rem);
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top .'
      '. main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar .';
    grid-template-rows: repeat(2, auto);
    grid-template-columns:
      calc(50vw - 30rem)
      repeat(24, 1fr)
      calc(50vw - 30rem);
  }

  @include media-breakpoint-up(xl) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top .'
      ' . main main main main main main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar . ';
    row-gap: 3rem;
    column-gap: 1rem;
    grid-template-columns:
      calc(50vw - 36rem) repeat(36, 1fr)
      calc(50vw - 36rem);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: calc(50vw - 40rem) repeat(36, 1fr) calc(50vw - 40rem);
  }
}

.videos-grid {
  display: grid;
  gap: 1rem; // Space between grid items
  grid-template-columns: repeat(3, 1fr); // 4 equal columns for smaller videos
  grid-auto-rows: auto; // Automatic height for rows

  .video-item {
    position: relative;

    &.featured {
      grid-column: span 2; // Span 2 columns
      grid-row: span 2; // Span 2 rows
      height: 100%; // Take full height for better visuals

      @media (max-width: 768px) {
        grid-column: span 4; // Span all columns on smaller screens
        grid-row: span 1; // Single row
      }
    }

    &:not(.featured) {
      height: 100%; // Ensure proper height for smaller videos
    }
  }
}

.gradient__top {
  grid-area: top;
}

.gradient__main {
  grid-area: main;
}

.gradient__sidebar {
  grid-area: sidebar;
}

.tabs {
  width: 100%;
  border-bottom: 2px solid #8c78aa;

  &__nav {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #bca9da;
    font-weight: bold;
  }

  .tab__item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: color 0.3s ease;

    &:hover,
    &.active {
      color: white;
    }
  }
}

.articles-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  width: 100%;

  .load-more-container {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}

.books-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.arguments-grid {
  // Additional grid styles for arguments can be added here if needed
}
</style>
