/**
 * Smoothly scrolls to a specified element or selector.
 *
 * @param {HTMLElement|string} elementOrSelector - The target element or CSS selector to scroll to.
 * @param {Object} [options={}] - Optional settings for scrolling behavior.
 * @param {number} [options.offset=0] - Additional offset to adjust the scroll position.
 * @param {ScrollBehavior} [options.easing='smooth'] - Scrolling behavior: 'smooth' or 'auto'.
 * @returns {void}
 */
export const scrollTo = (
  elementOrSelector: HTMLElement | string,
  options: { offset?: number; easing?: ScrollBehavior } = {}
): void => {
  const element =
    typeof elementOrSelector === 'string'
      ? (document.querySelector(elementOrSelector) as HTMLElement | null)
      : elementOrSelector;

  if (!element) {
    console.warn(`scrollTo: Element not found for selector or reference`, elementOrSelector);
    return;
  }

  if (!(element instanceof HTMLElement)) {
    console.warn(`scrollTo: Target is not a valid HTMLElement`, element);
    return;
  }

  const defaultOptions = {
    offset: 0,
    easing: 'smooth' as ScrollBehavior,
  };

  const finalOptions = { ...defaultOptions, ...options };

  window.scrollTo({
    top: element.offsetTop + finalOptions.offset,
    left: 0,
    behavior: finalOptions.easing,
  });
};
