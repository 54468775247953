<template lang="pug">
.convertion.rounded-4(@click="navigateToArticle")
  nuxt-img(
    v-if="image",
    :src="image",
    :alt="`${title} ${subtitle}`",
    loading="lazy",
    fit="cover",
    width="100%",
    height="100%",
  )
  .card-info
    EcreativAtomsHeading.pb-5.pt-4(tag="h3", size="4", class="fw-bold m-0 text-white" style="max-width: 300px;") {{ title }}
    .card-link
      EcreativAtomsLink(
        buttonType="Link",
        linkType="IsInternal",
        :link="getUrl",
        linkColor="white",
        fontWeight="bold",
        text="Learn More",
        underline="underline"
      )
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsLink from '../../atoms/Link.vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
    default: 'Title',
  },
  subtitle: {
    type: String,
    default: 'Subtitle',
  },
  link: {
    type: String,
    default: 'link',
  },
  category: {
    type: String,
    default: 'category',
  },
});

const getUrl = computed(() => `/${props.category}/${props.link}`);
const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.convertion {
  position: relative;
  width: 100%;
  height: 100%;
  // border-radius: $border-radius-lg;
  overflow: hidden;
  cursor: pointer;
}

.card-info {
  position: absolute;
  left: 48px;
  top: 28px;
  bottom: 28px;
}

.card-link {
  padding-top: 10px;
}
</style>
