<template lang="pug">
a.link(
  :href="`mailto:${email || text}?subject=${subject}`"
  rel="noopener noreferrer nofollow"
  target="_blank"
  @click="handleClick"
)
  slot(v-if='$slots.prevLabel' name='prevLabel' :text="text")
    | Email:&nbsp;{{ text }}
  template(v-else)
    | {{ text }}
  slot
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import {
    type InteractiveEmailElement,
    defaultInteractiveEmailElement,
} from 'data-types-config';
withDefaults(
    defineProps<InteractiveEmailElement>(),
    defaultInteractiveEmailElement,
);

const handleClick = () => {
    const gtm = useGtm();
    if (gtm) {
        gtm.trackEvent({
            event: 'email_link',
            value: parseInt('1', 10),
            currency: 'EUR',
            event_category: 'leads',
            event_label: `User Contact by Email`,
        });
    }
};
</script>
