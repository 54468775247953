<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path d="M0 0h96v96H0V0z" style="fill: none" />
      <path
        d="M34.9 54.5c9.6 0 17.5-7.8 17.5-17.5s-7.8-17.5-17.5-17.5S17.4 27.3 17.4 37s7.9 17.5 17.5 17.5zm0-26.1c4.8 0 8.7 3.9 8.7 8.7s-3.9 8.7-8.7 8.7c-4.8 0-8.7-3.9-8.7-8.7s3.9-8.7 8.7-8.7zm0 34.9C23.3 63.3 0 69.1 0 80.7v8.7h69.8v-8.7c0-11.6-23.2-17.4-34.9-17.4zM8.7 80.7c1-3.1 14.4-8.7 26.2-8.7 11.8 0 25.3 5.6 26.2 8.7H8.7zm52.7-52.1c3.7 5.1 3.7 11.8 0 17l7.3 7.4c8.8-8.8 8.8-22.1 0-31.7l-7.3 7.3zM83.2 6.5l-7.1 7.1c12.1 13.2 12.1 33 0 46.9l7.1 7.1c17-16.9 17.1-43.4 0-61.1z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
