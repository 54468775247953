<template lang="pug">
  div.meet-side-bar
    div.items-box
      EcreativMoleculesMeetForm(
        title="Find local Imams"
        show="checkbox"
        placeholder="W1 ABC"
      )
      EcreativMoleculesMeetProfileInformation(
        sectionHeading="Closest Immans near:"
        searchResult="W1 ABC"
      )
      EcreativMoleculesMeetProfileInformation(
        showBlock="show"
        title="Imam Rahel Ahmed"
        subtitle="Mubarak Mosque, Islamabad, Tilford"
        distance="3.1 mi"
        introduction="Introduction"
        :introText="introText"
        expect="What to Expect"
        :expectText="expectText"
        location="Location"
        :locationText="locationText"
        link="/"
        showLink="show"
      )
      EcreativMoleculesMeetProfileInformation(
        showBlock="show"
        title="Imam Ataul Fatir Tahir"
        subtitle="Mubarak Mosque, Islamabad"
        distance="3.1 mi"
      )
      EcreativMoleculesMeetProfileInformation(
        showBlock="show"
        title="Imam Mansoor Clarke"
        subtitle="Baitul Wahid Mosque, Feltham"
        distance="4.5 mi"
      )
      EcreativAtomsButton(
        label="Submit button"
        buttonType="IsButton"
        text="Send Message"
        context="dark"
        class="text-white justify-content-center mt-2"
        @click="submitForm"
      )
      EcreativMoleculesMeetForm(
        title="or Request a Call Back"
        show="button"
        placeholder="Enter your phone number"
        class="mt-5"
      )
</template>

<script setup>
import { ref } from 'vue';

const introText = ref(
  `Welcome to our local Imam's profile.
  A trusted sourced of guidance and support.`,
);

const expectText = ref(`
  A knowledgeable and compassionate scholar.
  Answers to your questions about Islam. Guidance on religiuos
  practices and rituals. Support in times of nedd or crisis
`);

const locationText = ref(`
  Mosque or Mission House Name / Introduction
`);

const submitForm = () => {
  //código para enviarel formulario (menesaje)
};
</script>

<style lang="scss" scoped>
.meet-side-bar {
  border-radius: $border-radius-sm;
  width: 100%;
}

.items-box {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.button {
  border-radius: $border-radius-xl;
}
</style>
