<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M7.38 65.54A7.36 7.36 0 0 1 0 58.26V8.36a.46.46 0 0 1 .78-.33l24.86 24.95a.48.48 0 0 1 0 .63L8.26 51.86a2.92 2.92 0 0 0 0 4.19 3.04 3.04 0 0 0 4.25 0l17.3-18.18a.48.48 0 0 1 .67 0l4.07 4.07a19.09 19.09 0 0 0 13.52 5.67 18.71 18.71 0 0 0 13.24-5.5l4.23-4.24a.48.48 0 0 1 .67 0l17.3 18.18a3.03 3.03 0 0 0 4.25 0 2.92 2.92 0 0 0 0-4.18L70.4 33.6a.43.43 0 0 1 0-.63L95.22 8.03a.46.46 0 0 1 .78.32v49.9a7.36 7.36 0 0 1-7.38 7.3ZM39 37.94 2.75 1.6A7.3 7.3 0 0 1 7.33 0h81.36a7.34 7.34 0 0 1 4.58 1.6L57.03 37.94a12.77 12.77 0 0 1-18.04 0Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
