<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :fill="iconColor"
    :style="iconStyle"
  >
    <title :id="iconName" lang="es">{{ iconName }} icon</title>

    <g fill="inherit">
      <path
        d="m2.01,2.01c2.67-2.68,7.01-2.69,9.7-.01,0,0,0,0,.01.01l36.28,36.29L84.28,2.01c2.68-2.68,7.03-2.68,9.71,0s2.68,7.03,0,9.71l-36.29,36.28,36.29,36.28c2.68,2.68,2.68,7.03,0,9.71s-7.03,2.68-9.71,0l-36.28-36.29L11.72,93.99c-2.68,2.68-7.03,2.68-9.71,0s-2.68-7.03,0-9.71l36.29-36.28L2.01,11.72c-2.68-2.67-2.69-7.01-.01-9.7,0,0,0,0,.01-.01Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
