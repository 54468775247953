import revive_payload_client_WuXru8Mh99 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5ni1JnDXAM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bo62o8dDgo from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_15gpXqiBKi from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aO9xsMuIhd from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IpuhUK4Ovb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_as1Kke1MME from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ASGcABqDKm from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/front/.nuxt/components.plugin.mjs";
import prefetch_client_AqlAb8NwVj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_edoa24gt2nw6epj4n6yllctkvy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bdfenpmaf1 from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@https+++codeload.github.com+nuxt+icon+tar.gz+0356c34fd57f5aba95e70f2d51362bacb75f4_opwwtoso5acsg4gbacxyeyywxy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollspy_client_mRTZl1UJDi from "/vercel/path0/apps/front/src/plugins/scrollspy.client.js";
import firebase_client_lOvfsw6Ppv from "/vercel/path0/apps/front/src/plugins/firebase.client.ts";
import form_fhHqzO4Fku from "/vercel/path0/apps/front/src/plugins/form.js";
import loader_GbMfCzpAFz from "/vercel/path0/apps/front/src/plugins/loader.ts";
import scroll_to_directive_mvfaQkBu2F from "/vercel/path0/apps/front/src/plugins/scroll-to-directive.js";
import scroll_to_helper_8mimme1nn3 from "/vercel/path0/apps/front/src/plugins/scroll-to-helper.ts";
import scrollToHash_client_JvX8Qke8oP from "/vercel/path0/apps/front/src/plugins/scrollToHash.client.js";
import toggle_collapse_directive_cygon8PgEz from "/vercel/path0/apps/front/src/plugins/toggle-collapse-directive.js";
import vee_validate_client_45gWhfAHXv from "/vercel/path0/apps/front/src/plugins/vee-validate.client.js";
import vue_click_away_QoBmAA3ck9 from "/vercel/path0/apps/front/src/plugins/vue-click-away.js";
import vue_gtm_client_3YPaNVLi9H from "/vercel/path0/apps/front/src/plugins/vue-gtm.client.js";
import vue_social_sharing_glaVIJc3fZ from "/vercel/path0/apps/front/src/plugins/vue-social-sharing.js";
import wp_api_provider_plugin_GTJSRz7MzG from "/vercel/path0/apps/front/src/plugins/wp-api-provider.plugin.js";
export default [
  revive_payload_client_WuXru8Mh99,
  unhead_5ni1JnDXAM,
  router_bo62o8dDgo,
  payload_client_15gpXqiBKi,
  navigation_repaint_client_aO9xsMuIhd,
  check_outdated_build_client_IpuhUK4Ovb,
  chunk_reload_client_as1Kke1MME,
  plugin_vue3_ASGcABqDKm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AqlAb8NwVj,
  plugin_bdfenpmaf1,
  scrollspy_client_mRTZl1UJDi,
  firebase_client_lOvfsw6Ppv,
  form_fhHqzO4Fku,
  loader_GbMfCzpAFz,
  scroll_to_directive_mvfaQkBu2F,
  scroll_to_helper_8mimme1nn3,
  scrollToHash_client_JvX8Qke8oP,
  toggle_collapse_directive_cygon8PgEz,
  vee_validate_client_45gWhfAHXv,
  vue_click_away_QoBmAA3ck9,
  vue_gtm_client_3YPaNVLi9H,
  vue_social_sharing_glaVIJc3fZ,
  wp_api_provider_plugin_GTJSRz7MzG
]