<template lang="pug">
EcreativOrganismsExpandableMediaArticle(
  :title="introSchema.title"
  :caption="introSchema.caption"
  :imageSrc="introSchema.imageSrc"
  :imageAlt="introSchema.imageAlt"
  :introText="introSchema.introText"
  :learnMoreText="introSchema.learnMoreText"
  :content="introSchema.content"
  )
</template>

<script setup>
import { computed, defineProps } from 'vue';
import EcreativOrganismsExpandableMediaArticle from '../organisms/ExpandableMediaArticle.vue';

const introSchema = {
  title: 'Sample Title for the Article',
  caption: 'Sample Caption for the Title',
  imageSrc: 'https://placehold.co/275x430/orange/white',
  imageAlt: 'Sample Alt Text for Image',
  introText:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut orci ut libero interdum rhoncus. Nullam aliquam dictum dolor, ac lacinia lorem convallis quis.',
  learnMoreText: 'Learn more about the sample subject...',
  content: [
    'This is a placeholder for the first section of content. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut orci ut libero interdum rhoncus. Nullam aliquam dictum dolor',
    'In this second section, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut orci ut libero interdum rhoncus. Nullam aliquam dictum dolor',
    'The third section Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut orci ut libero interdum rhoncus. Nullam aliquam dictum dolor.',
  ],
};
</script>
