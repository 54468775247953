<template lang="pug">
div
  .ytp.ratio.ratio-16x9.rounded-4(
    :data-embed="data",
    @click="updateLastPlayer"
  )
    .card--youtube.overlay
        EcreativAtomsHeading.cardTitle.mb-1.pt-3(
          v-if="title",
          tag="h4",
          size="6",
          context="white",
        ) {{ title }}
        .ytp-play-button
          i.icon
            EcreativAtomsSvgIcon(
              iconName="playFill",
              iconSize="sm",
              isCustom
            )
  EcreativAtomsHeading.mb-1.pt-3(
    v-if="title",
    tag="h4",
    size="6",
    context="white",
  ) {{ title }}
  EcreativAtomsParagraph.mb-1.pt-3(
    v-if="subtitle",
    tag="h4",
    context="white",
    size="6"
  ) {{ subtitle }}
  time.text-white(
    v-if="time",
    :datetime="time"
  ) {{ time }}
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
const { data, title, subtitle } = defineProps(['data', 'title', 'subtitle']);

const emit = defineEmits();

const updateLastPlayer = (event) => {
  emit('update-last-player', event);
};
</script>

<style lang="scss" scoped>
.elipse {
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
  width: 100%; /* Set the width to match the container */
  max-width: 50ch; /* Optional: Set a maximum width for readability */
  display: block; /* Ensure the paragraph behaves like a block-level element */
  box-sizing: border-box;
}

.ytp {
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  .icon {
    margin-left: 5px;
    svg {
      fill: $secondary;
    }
  }

  .card--youtube {
    overflow: hidden;
    height: 100%;
    margin: 0;
    background-position: 50%;
    background-size: cover;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.56);
      visibility: hidden;
    }

    &.overlay {
      &::after {
        background-color: rgba(0, 0, 0, 0.1);
        visibility: visible;
        transition: all 0.2s ease-in;
      }
    }

    .card-body {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      min-height: 0;
      margin: 0;
      padding: 2em 3em;

      @media screen and (min-width: 768px) {
        justify-content: space-around;
      }
    }

    .cardTitle {
      position: absolute;
      top: 0;
      left: 4%;
      z-index: 2;
      margin-top: 10px;
      margin-bottom: 0.75rem;
      color: $white;
      font-family: $headings-font-family;
      text-align: left;
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }

    .ytp-play-button {
      position: absolute;
      right: 30px;
      bottom: 15px;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 8px 0;
      border-radius: 74px;
      background-color: rgb(255, 255, 255);
      color: $primary;
      box-shadow:
        0 2px 2px 0 rgb(55 57 58 / 14%),
        0 3px 1px -2px rgb(52 68 70 / 20%),
        0 1px 5px 0 rgb(25 27 32 / 12%);
      font-size: 1.4rem;
      transition: all 0.2s ease-in;
    }

    &:hover {
      cursor: pointer;

      .cardTitle {
        opacity: 1;
        transition: opacity 0.4s ease-out;
      }

      .ytp-play-button {
        background-color: rgb(255, 255, 255);
        color: $secondary !important;
        box-shadow:
          0 2px 2px 0 rgba(0, 188, 212, 0.14),
          0 3px 1px -2px rgba(0, 188, 212, 0.2),
          0 1px 5px 0 rgba(0, 188, 212, 0.12);
        fill: $primary;
        fill-opacity: 1;
      }

      &.overlay {
        &::after {
          background-color: rgba(0, 0, 0, 0.6);
          visibility: visible;
        }
      }
    }
  }
}
</style>
