<script lang="ts" setup>
import { onMounted, defineProps, provide, computed } from 'vue';
import { useFetch } from '#imports';
// import { useArticlesStore } from '~/stores/ArticlesStore' // adjust path to your store

// Define props
defineProps({
  isPageLoading: {
    type: Boolean,
    default: false,
  },
});

// 1) Access the articles store
const articlesStore = useArticlesStore();

// 2) Optionally, fetch "elevenPoints" data if you still need it
const { data: elevenPoints } = await useFetch('/api/articles/articles', {
  params: {
    categoryKey: 'elevenPoints',
    perPage: 10,
    page: 1,
  },
});

// Optionally, provide articles as a computed for child components
provide(
  'articlesData',
  computed(() => articlesStore.articles),
);
provide(
  'latestArticles',
  computed(() => articlesStore.getLatestArticles(5)),
);

// 3) Provide "elevenPoints" to child components (optional)
provide('elevenPoints', elevenPoints?.value?.data);

// 4) On mount, fetch your first 6 articles from the store
onMounted(() => {
  // If no articles are in the store yet, load page 1
  if (articlesStore.articles.length === 0) {
    articlesStore.currentPage = 1;
    articlesStore.fetchArticles();
  }

  // Example: smooth-scroll if URL has "#about"
  if (window.location.href.includes('#about')) {
    const aboutElement = document.querySelector('#about') as HTMLElement;
    if (aboutElement) {
      window.scrollTo({
        top: aboutElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }
});
</script>

<template lang="pug">
.app
  slot
  EcreativOrganismsArgumentsModal
  EcreativMoleculesGoTop
</template>
