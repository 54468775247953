<template lang="pug">
.wrapper
  EcreativAtomsButton.back-to-top.bg-dark(
    v-show="visible"
    componentType="IsButton"
    text=""
    context="primary"
    linkColor="white"
    link="javascript:void(0)"
    @click="scrollTop"
  )
    template(#icon)
      EcreativAtomsSvgIcon(iconName='chevronUp' :isCustom='true', iconSize="sm")
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue';
import EcreativAtomsButton from '../atoms/Button.vue';
import EcreativAtomsSvgIcon from '../atoms/SvgIcon.vue';
const { $scrollTo } = useNuxtApp();

const visible = ref(false);

const scrollListener = () => {
  visible.value = window.scrollY > 150;
};

const scrollTop = () => {
  const body = document.querySelector('body');
  $scrollTo(body, 500, { offset: -60, easing: 'smooth' });
};

const throttle = (fn, wait) => {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

onBeforeUnmount(() => {
  window.removeEventListener('scroll', scrollListener);
});

onMounted(() => {
  window.addEventListener('scroll', throttle(scrollListener, 200));
});
</script>
<style lang="scss" scoped>
.wrapper {
  mix-blend-mode: difference;
  position: fixed;
  right: 8px;
  bottom: 90px;
  z-index: 9999;

  @include media-breakpoint-up(sm) {
    filter: invert(1);
    right: 24px;
  }
}

.back-to-top {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px !important;
  border-radius: 3px;
  color: white;
}
</style>
