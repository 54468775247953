<template lang="pug">
.searchbox.d-flex.align-items-center
  form#form.inputbox.d-flex.align-items-center.px-3.gap-2(@submit.prevent="onSearch")
    label(for="query" class="visually-hidden") Search
    span.icon-container
      EcreativAtomsSvgIcon.icon(
        iconName="search",
        iconSize="md",
        isCustom
      )
    input.search.ps-2.p-2(
      type="text"
      id="query"
      v-model="query"
      placeholder="Search True Islam"
      aria-label="Search"
    )
  .textbox.d-xl-flex.align-items-center
    ul.items.d-flex.m-0(role="tablist")
      li.item(
        v-for="tab in tabs"
        :key="tab"
        role="tab"
        :tabindex="selectedTab === tab ? 0 : -1"
        @click="selectTab(tab)"
        @keydown.space.prevent="selectTab(tab)"
        @keydown.enter.prevent="selectTab(tab)"
        :aria-selected="selectedTab === tab"
        :aria-controls="`panel-${tab}`"
      ) {{ tab }}
</template>

<script setup>
import { ref } from 'vue';

// Reactive state
const query = ref('');
const tabs = ['All', 'Articles', 'Books', 'Q&A Library', 'Beliefs'];
const selectedTab = ref('All');

// Methods
const onSearch = () => {
  console.log(`Search for: ${query.value}`);
};

const selectTab = (tab) => {
  selectedTab.value = tab;
  console.log(`Selected Tab: ${tab}`);
};
</script>

<style lang="scss" scoped>
.searchbox {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 55px;
  height: 55px;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.inputbox {
  flex: 1 1 25%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-right: 1px solid #cccccc;
  background-color: #f5f5f7;
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  background-color: transparent;
  flex-grow: 1;
  font-size: 16px;
  outline: none;
  font-weight: 700;
}

.search::placeholder {
  color: #000000;
}

.textbox {
  display: none;
  padding: 0 20px;
}

.items {
  gap: 2rem;
  list-style: none;
  display: flex;
  padding: 0;
}

.item {
  cursor: pointer;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
}

.item:hover,
.item:focus {
  color: #cc3366;
}

.item[aria-selected='true'] {
  color: #ffffff;
  background-color: #3e2073;
}

/* Responsive Design */
@include media-breakpoint-up(xl) {
  .textbox {
    display: flex;
    align-items: center;
  }
}
</style>
