import { defineNuxtPlugin } from '#app';
import { useGlobalStore } from '~/stores/globalStore';
import { useRouter } from 'vue-router';

/**
 * Nuxt plugin to manage a global loader during navigation with a 4-second fallback.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const globalStore = useGlobalStore();
  const router = useRouter(); // Use Vue Router directly
  let loaderTimeout: ReturnType<typeof setTimeout> | null = null;

  // Show loader when navigation starts
  router.beforeEach((to, from, next) => {
    // Clear any existing timeout
    if (loaderTimeout) {
      clearTimeout(loaderTimeout);
      loaderTimeout = null;
    }

    // Show the loader
    globalStore.SHOW_LOADER();

    // Set a fallback timeout to hide the loader after 4 seconds
    loaderTimeout = setTimeout(() => {
      globalStore.HIDE_LOADER();
      loaderTimeout = null;
    }, 4000); // 4 seconds max

    next();
  });


  // Hide loader after the page finishes rendering
  nuxtApp.hook('page:finish', () => {
    if (loaderTimeout) {
      clearTimeout(loaderTimeout); // Clear the fallback timeout
      loaderTimeout = null;
    }

    // Hide the loader with a delay to match animation
    setTimeout(() => {
      globalStore.HIDE_LOADER();
    }, 800); // Match your animation duration
  });

  // Hide loader after the page finishes rendering
  // router.afterEach(() => {
  //   if (loaderTimeout) {
  //     clearTimeout(loaderTimeout); // Clear the fallback timeout
  //     loaderTimeout = null;
  //   }

  //   // Ensure the loader is hidden with a delay to match animation
  //   setTimeout(() => {
  //     globalStore.HIDE_LOADER();
  //   }, 800); // Match your animation duration
  // });
});
