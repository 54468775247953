<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M50.99 0H8.82A8.91 8.91 0 0 0 0 9v78a8.91 8.91 0 0 0 8.82 9H50a8.91 8.91 0 0 0 8.82-9V9c0-4.97-2.95-9-7.82-9ZM29.4 90a6 6 0 1 1 5.88-6 5.93 5.93 0 0 1-5.88 6Zm20.59-20.25a2.23 2.23 0 0 1-2.2 2.25H11.02a2.23 2.23 0 0 1-2.2-2.25v-58.5A2.23 2.23 0 0 1 11.02 9h36.75a2.23 2.23 0 0 1 2.2 2.25Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
