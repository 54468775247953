<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M38.5 1.34a3 3 0 0 0-5 0l-18 27A3 3 0 0 0 18 33h.59L6.46 52.4A3 3 0 0 0 9 57h1.15L.32 76.66A3 3 0 0 0 3 81h27v15h12V81h27a3 3 0 0 0 2.68-4.34L61.86 57h1.15a3 3 0 0 0 2.54-4.59L53.41 33H54a3 3 0 0 0 2.5-4.66l-18-27Zm-11.88 27.2A3 3 0 0 0 24 27h-.4L36 8.4 48.4 27H48a3 3 0 0 0-2.54 4.59L57.59 51H57a3 3 0 0 0-2.68 4.34L64.14 75H7.85l9.83-19.66A3 3 0 0 0 15 51h-.59l12.13-19.41a3 3 0 0 0 .08-3.04Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
