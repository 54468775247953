<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M94.92 1.32a3.7 3.7 0 0 1 0 5.23l-44.19 44.3a3.68 3.68 0 0 1-5.21 0L1.33 6.55a3.7 3.7 0 1 1 5.2-5.21l41.6 41.69 41.58-41.7a3.7 3.7 0 0 1 5.22 0Z"
        fill-rule="evenodd"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import useIconSetUp from './useIconSetUp';

const props = withDefaults(
  defineProps<{
    iconName: string;
    iconColor?: string;
    fontSize?: string;
    width: string | number;
    height: string | number;
  }>(),
  {
    iconName: 'IconDefault',
    iconColor: 'currentColor',
    fontSize: '1rem',
    width: 96,
    height: 96,
  },
);

const { widthValue, heightValue, viewBoxProp, iconStyle } = useIconSetUp(props);
</script>
