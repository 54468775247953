<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M1.08 1.08a3.7 3.7 0 0 1 5.23 0l44.3 44.3a3.7 3.7 0 0 1 0 5.23l-44.3 44.3A3.7 3.7 0 1 1 1.1 89.7L42.79 48 1.08 6.31a3.7 3.7 0 0 1 0-5.23Z"
        fill-rule="evenodd"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import useIconSetUp from './useIconSetUp';

const props = withDefaults(
  defineProps<{
    iconName: string;
    iconColor?: string;
    fontSize?: string;
    width: string | number;
    height: string | number;
  }>(),
  {
    iconName: 'IconDefault',
    iconColor: 'currentColor',
    fontSize: '1rem',
    width: 96,
    height: 96,
  },
);

const { widthValue, heightValue, viewBoxProp, iconStyle } = useIconSetUp(props);
</script>
