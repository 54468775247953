<template lang="pug">
.convertion.rounded-4(@click="navigateToArticle")
  nuxt-img(
    v-if="image",
    :src="image",
    :alt="`${title} ${subtitle}`",
    loading="lazy",
    fit="cover",
    width="100%",
    height="100%",
  )
  .card-info
    .d-flex.flex-column.justify-content-center.h-100
      EcreativAtomsHeading.pb-3(tag="h3", context="white", class="fw-bold m-0" style="max-width: 14ch;") Repentance:
      EcreativAtomsHeading.pb-3(tag="h3", context="white", class="fw-bold m-0" style="max-width: 14ch;") The Purpose of Bai’at
      EcreativAtomsParagraph(tag="h4", context="white", class="m-0") {{ subtitle }}
      .card-button
        EcreativAtomsButton(
          buttonType="Link",
          linkType="IsInternal",
          :link="getUrl",
          context="dark",
          class="fw-bold justify-content-center",
          textColor="white",
          text="Read More"
        )
</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsButton from '../../atoms/Button.vue';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  category: {
    type: String,
    default: '',
  },
});
const getUrl = computed(() => `/${props.category}/${props.link}`);
const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.convertion {
  position: relative;
  width: 100%;
  height: 100%;
  // border-radius: $border-radius-lg;
  overflow: hidden;
  cursor: pointer;
}

.card-info {
  position: absolute;
  padding-left: 48px;
  top: 0;
  bottom: 0;
  height: 100%;
}

.card-button {
  width: 65%;
  padding-top: 50px;
}
</style>
