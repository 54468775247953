<script lang="ts" setup>
import { useFetch } from '#imports';
import { type ShellData } from 'data-types-config';

const globalStore = useGlobalStore();

// 1. Call useFetch with a key and a default factory
const {
  data: shellData,
  error,
  status,
  refresh,
} = await useFetch<ShellData>('/api/shell/navigation', {
  key: 'shell-navigation',
});

// 2. Log status and error
// console.log('Shell navigation status:', status.value);

if (error.value) {
  console.error('Error fetching shell navigation data:', error.value);
}

// 3. Destructure the data safely with default fallbacks
const {
  beliefsLinks = [],
  questionsAndAnswersLinks = [],
  socialLinks = [],
  legalLinks = [],
  secondNavLinks = [],
  beliefsLinksHeader = [],
  questionsAndAnswersLinksHeader = [],
  campaignsLinksHeader = [],
  discoverLinksHeaderMobile = [],
  questionsAndAnswersLinksHeaderMobile = [],
  moreLinksHeaderMobile = [],
} = shellData.value || {};

const mainNavLinks2 = [
  {
    id: 3172,
    parent: '0',
    text: 'Beliefs',
    link: '#',
    linkType: 'IsInternal',
    fontWeight: 'normal',
    hoverColor: '',
    isUnderLine: 'underline',
    linkColor: '',
    variant: 'default',
    is_submenu: 'no',
    level: '2',
    order: 1,
    variation: '',
    children: [
      {
        id: 3173,
        parent: '3172',
        text: 'Allah',
        link: '/beliefs/god',
        linkType: 'IsInternal',
        fontWeight: 'normal',
        hoverColor: '',
        isUnderLine: 'underline',
        linkColor: '',
        variant: 'highlighted',
        level: '1',
        is_submenu: 'no',
        order: 2,
        children: [
          {
            id: 3174,
            parent: '3173',
            text: 'Who is God?',
            link: '/beliefs/god#h1-1',
            linkType: 'IsInternal',
            fontWeight: 'normal',
            hoverColor: '',
            isUnderLine: 'underline',
            linkColor: '',
            variant: 'underlined',
            level: '0',
            is_submenu: 'no',
            order: 3,
            children: [],
          },
          {
            id: 3175,
            parent: '3173',
            text: 'How Can We Know God?',
            link: '/beliefs/god#h1-2',
            linkType: 'IsInternal',
            fontWeight: 'normal',
            hoverColor: '',
            isUnderLine: 'underline',
            linkColor: '',
            variant: 'underlined',
            level: '0',
            is_submenu: 'no',
            order: 4,
            children: [],
          },
        ],
      },
      {
        id: 3176,
        parent: '3172',
        text: 'Prophets',
        link: '/beliefs/the-prophet-muhammad#h1-1',
        linkType: 'IsInternal',
        fontWeight: 'normal',
        hoverColor: '',
        isUnderLine: 'underline',
        linkColor: '',
        variant: 'highlighted',
        level: '1',
        is_submenu: 'no',
        order: 5,
        children: [
          {
            id: 3177,
            parent: '3176',
            text: 'The Prophet Muhammad',
            link: '/beliefs/the-prophet-muhammad',
            linkType: 'IsInternal',
            fontWeight: 'normal',
            hoverColor: '',
            isUnderLine: 'underline',
            linkColor: '',
            variant: 'underlined',
            level: '0',
            is_submenu: 'no',
            order: 6,
            children: [],
          },
          {
            id: 3178,
            parent: '3176',
            text: 'The Promised Messiah',
            link: '/beliefs/the-promised-messiah',
            linkType: 'IsInternal',
            fontWeight: 'normal',
            hoverColor: '',
            isUnderLine: 'underline',
            linkColor: '',
            variant: 'underlined',
            level: '0',
            is_submenu: 'no',
            order: 7,
            children: [],
          },
        ],
      },
    ],
  },
];

const mainNavLinks = [
  ...beliefsLinksHeader,
  ...questionsAndAnswersLinksHeader,
  ...campaignsLinksHeader,
];
const mainNavLinksMobile = [
  ...discoverLinksHeaderMobile,
  ...moreLinksHeaderMobile,
  ...questionsAndAnswersLinksHeaderMobile,
];

const footerLinks = [
  ...beliefsLinks,
  ...questionsAndAnswersLinks,
  ...socialLinks,
];
</script>

<template lang="pug">
EcreativAtomsSpinner(v-if="globalStore.isPageLoading", isFullPage, hasBackground)
  LogoLoader
nuxtErrorBoundary
  template(#error="{ error }")
    // Fallback UI for errors
    div(class="error-boundary")
      h1 Error Loading the Page
      p {{ error.message }}
      button(@click="refresh") Retry
  Shell
    template(#header)
      EcreativOrganismsPresentingLogoAndCallToNavigateMainDirectory2(
        :mainNavLinks="mainNavLinks"
        :mainNavLinksMobile="mainNavLinksMobile"
        :secondNavLinks="secondNavLinks"
      )
    template(#footer)
      EcreativOrganismsFooterNavigationAndLegal(
        :footerLinks="footerLinks"
        :legalLinks="legalLinks"
      )
</template>
