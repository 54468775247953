import { defineStore, acceptHMRUpdate } from 'pinia';

export const useArticlesStore = defineStore('ArticlesStore', {
  state: () => ({
    articles: [] as Array<any>,
    isLoading: false,
    currentPage: 1,
    totalPages: 1,
    hideLoadMore: false,
  }),

  actions: {
    async fetchArticles() {
      // Stop if we've already passed the total available pages
      if (this.currentPage > this.totalPages) {
        this.hideLoadMore = true;
        return;
      }

      this.isLoading = true;
      try {
        // Request 6 articles per page
        const articlesData = await $fetch(`/api/articles/articles`, {
          params: {
            categoryKey: 'articles',
            per_page: 10,
            page: this.currentPage,
          },
        });

        const articles = articlesData?.data ?? [];
        const totalPages = articlesData?.totalPages ?? 1;

        // Merge new articles with existing ones
        this.articles = [...this.articles, ...articles]
        this.totalPages = totalPages;
        this.hideLoadMore = this.currentPage >= this.totalPages;
      } catch (error) {
        this.handleError('Error fetching articles:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async loadMoreArticles() {
      this.currentPage++;
      await this.fetchArticles();
    },

    addArticles(newArticles: Array<any>) {
      this.articles = [...this.articles, ...newArticles];
    },

    handleError(message: string, error: any) {
      console.error(message, error);
    },
  },

  getters: {
    getArticles: (state) => state.articles,
    getLatestArticles: (state) => (count: number) => {
      return state.articles.slice(0, count);
    },
  },
});

// HMR support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticlesStore, import.meta.hot));
}
