<template lang="pug">
#content(ref="content")
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';

const colors = [
  '#3f2a89',
  '#3f2a89 ',
  // '#55266c',
  '#6b214f',
  '#000000',
  // '#030b22',
  '#091940',
  // '#0b1e48',
  '#1f435d',
  '#70e2b3',
  '#70e2b3 ',
  // '#75dabf',
  '#75d3d0',
  '#77b3e9',
  '#537df7',
  '#3b58fc',
  '#212529',
];

const content = ref<HTMLElement | null>(null);

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.replace('#', ''), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

function blendColors(
  color1: { r: number; g: number; b: number },
  color2: { r: number; g: number; b: number },
  fraction: number,
) {
  return {
    r: Math.round(color1.r + (color2.r - color1.r) * fraction),
    g: Math.round(color1.g + (color2.g - color1.g) * fraction),
    b: Math.round(color1.b + (color2.b - color1.b) * fraction),
  };
}

function handleScroll() {
  const scrollPosition = window.scrollY;
  const totalHeight = document.body.scrollHeight - window.innerHeight;

  let scrollFraction = 0;
  if (totalHeight > 0) {
    scrollFraction = scrollPosition / totalHeight;
  }

  // Clamp scroll fraction between 0 and 1
  scrollFraction = Math.max(0, Math.min(1, scrollFraction));

  // Multiply by the number of "transitions" (colors.length - 1)
  const normalized = scrollFraction * (colors.length - 1);

  // This is the base index for the current color transition
  let index = Math.floor(normalized);

  // This is how far we are between the current color and the next color
  let fraction = normalized - index;

  // Clamp the index so it never goes beyond the second-to-last color
  if (index >= colors.length - 1) {
    index = colors.length - 1;
    fraction = 0;
  }

  const c1 = hexToRgb(colors[index]);
  const c2 = hexToRgb(colors[Math.min(index + 1, colors.length - 1)]);
  const blended = blendColors(c1, c2, fraction);

  document.body.style.backgroundColor = `rgb(${blended.r}, ${blended.g}, ${blended.b})`;
}

onMounted(() => {
  // Set the initial background color to the first color in the array
  document.body.style.backgroundColor = colors[0];

  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
