import { defineNuxtPlugin } from '#app';
import { nextTick, watch } from 'vue';
import { scrollTo } from '@/assets/javascripts/scroll';

/**
 * Nuxt plugin to handle scrolling to elements based on the URL hash.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();

  /**
   * Wait for the DOM and the target element to be available, then scroll.
   */
  const waitForElementAndScroll = async (hash) => {
    if (!hash) return;

    let retries = 20; // Maximum retries for waiting
    while (retries > 0) {
      await nextTick(); // Wait for Vue's DOM updates
      const element = document.querySelector(hash);

      if (element) {
        scrollTo(element, {
          offset: 50,
          easing: 'smooth',
        });
        return;
      }

      retries -= 1;
      await new Promise((resolve) => setTimeout(resolve, 200)); // Wait for 200ms before retrying
    }

    console.warn(`Element with hash "${hash}" not found after waiting.`);
  };

  /**
   * Scroll to the target element specified by the hash.
   */
  const scrollToHash = async () => {
    if (route.hash) {
      await waitForElementAndScroll(route.hash);
    }
  };

  // Trigger scroll after the page finishes rendering
  nuxtApp.hook('page:finish', scrollToHash);

  // Re-run scrollToHash when route changes dynamically
  watch(
    () => route.hash,
    async (newHash) => {
      if (newHash) {
        await scrollToHash();
      }
    },
    { immediate: true }
  );
});
