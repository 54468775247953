import { computed } from 'vue';
import { type InteractiveButtonElement, ComponentType } from 'data-types-config';

import Link from '../components/atoms/Link.vue';
import IsInput from '../components/atoms/Links/IsInput.vue';
import IsInternal from '../components/atoms/Links/IsInternal.vue';
import IsExternal from '../components/atoms/Links/IsExternal.vue';
import IsButton from '../components/atoms/Links/IsButton.vue';
import IsFake from '../components/atoms/Links/IsFake.vue';
import IsEmail from '../components/atoms/Links/IsEmail.vue';
import IsTelephone from '../components/atoms/Links/IsTelephone.vue';

export default function useLinkLogic(props: Partial<InteractiveButtonElement>) {
  const typesMap = {
    IsEmail,
    IsExternal,
    IsFake,
    IsInput,
    IsInternal,
    IsTelephone,
    IsButton,
    Link
  };

  const isType = computed(() => {
    const typeKey = props.componentType as ComponentType;
    return typesMap[typeKey] || null;
  });

  const allClasses = computed(() => {
    // Determine direction class
    const directionClass = (() => {
      switch (props.direction) {
        case 'bottom':
          return 'flex-column';
        case 'top':
          return 'flex-column-reverse';
        case 'left':
          return 'flex-row-reverse';
        case 'right':
          return 'flex-row';
        default:
          return ''; // Empty string for default case
      }
    })();

    // Compile all classes, including additional and conditional ones
    const additionalClasses = [
      props.context !== 'none' ? `btn` : '',
      props.size === 'lg' ? 'text-uppercase' : '',
      `btn-${props.context}`,
      `btn-${props.size}`,
      props.fontWeight ? `fw-${props.fontWeight}` : '',
      props.isBlock ? 'w-100' : '',
      props.isDisabled ? 'button--disabled disable' : '',
    ].filter(Boolean);

    // Combine textDecorationClass, directionClass, classObjectStrings, and additionalClasses
    return [directionClass, ...additionalClasses].join(' ');
  });

  const ariaDisabled = computed(() => (props.isDisabled ? 'true' : 'false'));

  const tabIndexValue = computed(() => (props.isDisabled ? '-1' : '0'));

  // @click="disabled && $event.preventDefault()"
  const preventDisabledClick = (event: MouseEvent) => {
    if (props.isDisabled) event.preventDefault();
  };

  return {
    allClasses,
    ariaDisabled,
    tabIndexValue,
    preventDisabledClick,
    isType,
  };
}
