<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M81.47 14.02A47.48 47.48 0 0 0 6.73 71.35L0 96l25.17-6.49A47.74 47.74 0 0 0 81.47 14Zm-9.95 47.2-1.04 3.64-1.3 1.55-1.56 1.3-.52.53-1.82 1.03a9.5 9.5 0 0 1-3.9 1.04h-2.59l-2.59-.52a46.52 46.52 0 0 1-18.68-9.85l-2.08-2.07-1.03-1.04a43.25 43.25 0 0 1-9.6-13.23l-.27-.78a13.23 13.23 0 0 1 1.55-14.02l1.31-1.55 1.55-1.04.27-.27 1.56-.27h2.86a3.08 3.08 0 0 1 1.82 1.3 102.78 102.78 0 0 1 4.15 9.86 3.19 3.19 0 0 1-.52 2.59l-1.3 1.82-1.83 2.08-.27 1.3.52 1.04.27.52a28.63 28.63 0 0 0 4.93 6.22l1.83 1.55a25.55 25.55 0 0 0 7.52 4.94l1.31.52a2.13 2.13 0 0 0 1.04.51h.27a1.45 1.45 0 0 0 1.3-.78l3.9-4.42 1.82-.52.79.27 6.74 3.1 2.86 1.32.78.51v1.83Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
