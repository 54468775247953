<template lang="pug">
.faq.rounded-4
  .content
    .box-title(style="max-width: 300px")
      EcreativAtomsHeading.pb-3(
        v-if="title"
        tag="h3"
        size="4"
        class="title mb-0"
      ) {{ title }}
      EcreativAtomsParagraph.mb-4.pb-5(
        v-if="subtitle"
        class="title mb-0"
      ) {{ subtitle }}
      EcreativAtomsLink(
        buttonType="Link",
        linkType="IsInternal",
        :link="getUrl",
        linkColor="dark",
        fontWeight="bold",
        text="Learn More",
        underline="underline"
      )
    //- .items
    //-   EcreativAtomsLink(
    //-     v-for="(faq, index) in faqs"
    //-     :key="index"
    //-     linkType="IsInternal"
    //-     :link="getUrl"
    //-     :text="faq.text"
    //-     isUnderLine="none"
    //-     linkColor="dark"
    //-     class="item"
    //-     @click="navigateToArticle"
    //-   )
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsHeading from '../atoms/Heading.vue';
import EcreativAtomsLink from '../atoms/Link.vue';

const router = useRouter();

const props = defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  subtitle: {
    type: String,
    default: 'Subtitle',
  },
  faqs: {
    type: Array,
    default: () => [],
  },
  link: {
    type: String,
    default: 'linkDefault',
  },
  category: {
    type: String,
    default: 'category',
  },
});

const getUrl = computed(() => `${props.category}/${props.link}`);

const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.faq {
  background-color: $blue-100;
  // border-radius: $border-radius-lg;
  max-height: max-content;
  padding: 40px;
  // border-radius: 1rem;
}

.items {
  border-top: 1px solid;
}

.item {
  border-bottom: 1px solid;
  display: block;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 5px;
}

.item:last-child {
  border: none;
}
</style>
