<template lang="pug">
.ytp-list
  EcreativBaseVideoPlayer2.w-100.d-flex.justify-content-between.flex-column(
    v-for="(video, index) in videos"
    :key="index"
    :data="video.id"
    :subtitle="video.subtitle"
    @update-last-player="playerOnClick($event)"
  )
</template>

<script setup>
import { ref, onMounted } from 'vue';
import loadYouTubeIframeApi from '@/services/youtube/loadYouTubeIframeApi';

const videos = ref([
  {
    id: 'jekc6EvWjrw',
    subtitle: "Survival and God's Protection: Truth of Ahmad (SERIES) – Part 1",
  },
  {
    id: 'oN6rOTtY070',
    subtitle: '72 Sects Prove Ahmadiyya Islam TRUE: Truth of Ahmad (as) – PART 2 (SERIES)',
  },
  {
    id: '7swEFr7Q2a4',
    subtitle: 'Caliphate Proves Ahmadiyya Islam True: Truth of Ahmad (SERIES) – Part 3',
  },
  {
    id: 'T3zZVa2Pu6E',
    subtitle: "Secrets of Surah al-Fatiha & Durood: Truth of Ahmad SERIES – Part 4",
  },
]);

const players = ref({});
const lastPlayerChanged = ref(null);
const queryElem = ref(null);

const getQueryElement = (elem) => {
  if (typeof elem === 'string') {
    return document.querySelector(elem);
  }
  return elem;
};

const initPlayers = () => {
  const items = document.querySelectorAll('.ytp');

  items.forEach((item, index) => {
    if (!item.id) {
      item.id = index.toString();
      const source = `https://img.youtube.com/vi/${item.dataset.embed}/0.jpg`;
      const img = item.querySelector('.card--youtube');
      if (img) img.style.backgroundImage = `url(${source})`;

      players.value[`player${index}`] = {
        id: item.id,
      };
    }
  });
};

const playerOnClick = (event) => {
  const target = event.currentTarget;
  const targetId = target.id;

  if (target.querySelector(`#player${targetId}`)) {
    return;
  }

  const card = target.querySelector('.card--youtube');
  if (card) {
    card.classList.remove('overlay');
    card.innerHTML = '';

    const playerDiv = document.createElement('div');
    playerDiv.id = `player${targetId}`;
    card.appendChild(playerDiv);

    players.value[`player${targetId}`] = {
      yt: new YT.Player(`player${targetId}`, {
        height: '100%',
        width: '100%',
        videoId: target.dataset.embed,
        playerVars: { rel: 0 },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      }),
    };
  }
};

const pauseVideo = (id) => {
  if (players.value[id]) {
    players.value[id].yt.pauseVideo();
  }
};

const onPlayerReady = (event) => {
  event.target.playVideo();

  if (lastPlayerChanged.value && lastPlayerChanged.value !== event.target.a.id) {
    pauseVideo(lastPlayerChanged.value);
  }
};

const onPlayerStateChange = (event) => {
  if (event.data === YT.PlayerState.UNSTARTED || event.data === YT.PlayerState.PLAYING) {
    if (lastPlayerChanged.value && lastPlayerChanged.value !== event.target.a.id) {
      pauseVideo(lastPlayerChanged.value);
    }
    lastPlayerChanged.value = event.target.a.id;
  }
};

onMounted(() => {
  queryElem.value = getQueryElement('.ytp-list');
  initPlayers();

  if (!window.youtubeIframeAPI) {
    window.youtubeIframeAPI = loadYouTubeIframeApi();
  }
});
</script>

<style lang="scss" scoped>
.ytp-list {
  display: block;

  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
</style>
