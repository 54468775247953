<template lang="pug">
.card-article.d-flex.gap-4
  .card-image.rounded-4
    nuxt-img(
        v-if="image"
        :src="image"
        :alt="`${title} ${subtitle}`"
        format="png"
        loading="lazy"
        width="100%"
        height="100%"
    )
  .info
    time.time.text-black(:datetime="time") {{ formattedDate }}
    EcreativAtomsHeading(tag="h4" size="6" class="m-0 pt-1") {{ title }}
    EcreativAtomsHeading(tag="h4" class="fs-6 m-0 pb-1 fw-normal") {{ subtitle }}
    EcreativAtomsLink(
      linkType="IsInternal"
      :link="getUrl"
      context="white"
      class="link text-black"
      text="Read more"
      hoverColor="#0577e2"
    )
</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsLink from '../../atoms/Link.vue';

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  category: {
    type: String,
    default: '',
  },
  time: {
    type: String,
    default: '2023-01-01',
  },
});

const getUrl = computed(() => `/${props.category}/${props.link}`);

const formattedDate = computed(() => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
  };
  return new Date(props.time).toLocaleDateString('en-US', options);
});
</script>

<style lang="scss" scoped>
.card-article {
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.card-image {
  img {
    // border-radius: 10px;
    height: auto;
    max-width: 150px;
    min-width: 100px;
  }
}

.info {
  .time,
  .link {
    display: block;
    font-size: 0.875rem;
  }

  .link {
    color: black;
    text-decoration: underline;

    &:hover {
      color: #0577e2;
    }
  }
}

@include media-breakpoint-up(xl) {
  .card-article {
    flex-direction: row;
  }
}
</style>
