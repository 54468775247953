<template lang="pug">
.convertion.rounded-4(@click="navigateToArticle")
  nuxt-img(
    v-if="image",
    :src="image",
    :alt="`${title} ${subtitle}`",
    loading="lazy",
    height="100%",
    width="100%"
  )
  .card-info
    EcreativAtomsHeading.pb-3(tag="h3", size="4", class="fw-bold m-0 text-black" style="color: #003366 !important; max-width: 200px;") {{ title }}
    .card-link
      EcreativAtomsLink(
        buttonType="Link",
        linkType="IsInternal",
        :link="getUrl",
        :linkColor="linkColor",
        fontWeight="bold",
        text="View Conditions",
        underline="underline"
      )
</template>

<script lang="ts" setup>
import { computed, defineProps, withDefaults } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsLink from '../../atoms/Link.vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  headerlineSuplement: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  category: {
    type: String,
    default: '',
  },
  linkColor: {
    type: String,
    default: 'white',
  },
  borderRadius: {
    type: Number,
    default: 1,
  },
});

const getUrl = computed(() => `/${props.category}/${props.link}`);
const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.convertion {
  position: relative;
  width: 100%;
  height: 100%;
  // border-radius: $border-radius-lg;
  overflow: hidden;
  cursor: pointer;
}

.card-info {
  position: absolute;
  left: 48px;
  bottom: 15px;
}

.card-link {
  padding-top: 10px;
}
</style>
