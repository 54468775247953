import { nextTick } from 'vue';
import { defineNuxtPlugin } from '#app';
import { scrollTo } from '@/assets/javascripts/scroll';

/**
 * Nuxt plugin to provide a global `$scrollTo` utility for smooth scrolling.
 *
 * This plugin uses the `scrollTo` helper from the `utils/scroll` module.
 *
 * @module plugins/scrollTo
 */
export default defineNuxtPlugin(() => {
  return {
    provide: {
      /**
       * Smoothly scrolls to a specified element or selector.
       *
       * @function
       * @param {HTMLElement|string} elementOrSelector - The target element or CSS selector to scroll to.
       * @param {Object} [options={}] - Optional settings for scrolling behavior.
       * @param {number} [options.offset=0] - Additional offset to adjust the scroll position.
       * @param {ScrollBehavior} [options.easing='smooth'] - Scrolling behavior: 'smooth' or 'auto'.
       * @returns {void}
       *
       * @example
       * // Scroll to an element by selector
       * this.$scrollTo('#myElement', { offset: -100 });
       *
       * @example
       * // Scroll to a DOM element
       * const element = document.getElementById('myElement');
       * this.$scrollTo(element, { easing: 'auto' });
       */
      scrollTo: (elementOrSelector: HTMLElement | string, options: { offset?: number; easing?: ScrollBehavior } = {}): void => {
        nextTick(() => {
          scrollTo(elementOrSelector, options);
        });
      },
    },
  };
});
