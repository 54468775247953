<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path
        d="M33.6 0A33.58 33.58 0 0 0 0 33.6C0 58.8 33.6 96 33.6 96s33.6-37.2 33.6-62.4A33.58 33.58 0 0 0 33.6 0Zm0 45.6a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
