<template lang="pug">
time(:class="computedClasses" :datetime="datetime") {{ formattedDate }}
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  datetime: {
    type: [String, Number],
    required: true,
  },
  size: {
    type: String,
    default: 'none',
    validator: (value) =>
      ['1', '2', '3', '4', '5', '6', '7', 'none'].includes(value),
  },
  context: {
    type: String,
    default: 'none',
    validator: (value) =>
      [
        'none',
        'primary',
        'secondary',
        'dark',
        'light',
        'white',
        'danger',
        'success',
      ].includes(value),
  },
  fontWeight: {
    type: String,
    default: 'normal',
    validator: (value) =>
      ['normal', 'bold', 'light', 'semibold'].includes(value),
  },
});

const computedClasses = computed(() => {
  const baseClasses = [];

  if (props.size !== 'none') {
    baseClasses.push(`fs-${props.size}`);
  }

  baseClasses.push(`fw-${props.fontWeight}`);

  if (props.context !== 'none') {
    baseClasses.push(`text-${props.context}`);
  }

  return baseClasses.join(' ');
});

const formattedDate = computed(() => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const date = new Date(props.datetime);
  return isNaN(date.getTime())
    ? 'Invalid Date'
    : date.toLocaleDateString('en-US', options);
});
</script>
