<template lang="pug">
section.my-5.py-5
  #read.scrollIntoView.gradient__grid
    .gradient__top
      EcreativMoleculesTitleSection.mb-5(
        title="Read"
        subtitle="Dive into Islamic Wisdom and Knowledge"
      )
      .tabs
        ul.tabs__nav
          li.tab__item(
            v-for="tab in tabs"
            :key="tab.label"
            :class="{ active: tab.label === selected }"
            @click="setSelected(tab.label)"
          )
            span.tab__label
              | {{ tab.label }}
              span(v-if="tab.count") ({{ tab.count }})
    .gradient__main
      .tabs__content.h-100
        .articles-grid(v-if="selected === 'Articles'")
          EcreativMoleculesCardsCard7.item(
            v-for='item in articles'
            :id='item.id',
            :key='item.id',
            :image='item.image',
            :title='item.title',
            :content="''",
            :truncateContent="''",
            :link='item.slug',
            linkLabel="Read More",
            category='articles',
            :date='item.date'
          )
          .load-more-container.pt-4
            EcreativMoleculesButtonsLoadMore.mb-0(
              v-if="!articlesStore.hideLoadMore",
              :isLoading="articlesStore.isLoading"
            )
        .books-grid(v-if="selected === 'Books'")
          EcreativMoleculesCardsCard3(
            image="/images/books/The-Advent-of-the-Promised.png"
            link="https://files.alislam.cloud/pdf/The-Advent-of-The-Promised-Messiah.pdf"
            title="The Advent of the Promised Messiah"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/True-Justice-and-Peace.png"
            link="https://files.alislam.cloud/pdf/True-Justice-and-Peace.pdf"
            title="True Justice and Peace"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/Islam-and-Europe.png"
            link="https://files.alislam.cloud/pdf/The-Advent-of-The-Promised-Messiah.pdf"
            title="The Advent of the Promised Mv"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/The-Truth-Unveiled.png"
            link="https://files.alislam.cloud/pdf/The-Truth-Unveiled.pdf"
            title="The Truth Unveiled"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/Our-God.png"
            link="https://files.alislam.cloud/pdf/Our-God.pdf"
            title="Our God"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/The-Philosophy-of-the.png"
            link="https://files.alislam.cloud/pdf/Philosophy-of-Teachings-of-Islam.pdf"
            title="The Philosophy of the Teaching of Islam"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/Hallmarks-of-the-Saints.png"
            link="https://files.alislam.cloud/pdf/Hallmarks-of-the-Saints.pdf"
            title="Hallmarks of the Saints"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/A-message-of-peace.png"
            link="https://files.alislam.cloud/pdf/Message-of-Peace.pdf"
            title="A Message of Peace"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/An-Elementary-Study-of-Islam.png"
            link="https://files.alislam.cloud/pdf/Elementary-Study-of-Islam.pdf"
            title="An Elementary Study of Islam"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/World-Crisis-and-the-Pathway.png"
            link="https://files.alislam.cloud/pdf/World-Crisis-and-Pathway-to-Peace.pdf"
            title="World Crisis and the Pathway"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/A-Misconception-Removed.png"
            link="https://files.alislam.cloud/pdf/A-Misconception-Removed.pdf"
            title="A Misconception Removed"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/Life-of-Muhammad.png"
            link="https://files.alislam.cloud/pdf/Life-of-Muhammad.pdf"
            title="Life of Muhammad"
            subtitle="Mirza Tahir Ahmad"
          )
          EcreativMoleculesCardsCard3(
            image="/images/books/Some-Distinctive-Features.png"
            link="https://files.alislam.cloud/pdf/Some-Distinctive-Features-of-Islam.pdf"
            title="Some Distinctive Features"
            subtitle="Mirza Tahir Ahmad"
          )
        .arguments-grid.h-100(v-if="selected === 'Arguments'")
          .argument-item.bg-white.p-5.mb-5.rounded-4.h-100
            EcreativAtomsHeading.modalTitle(tag="h3") Arguments
            iframe.h-100.idIframe(src='https://trueislam.co.uk/argumentsPublic/', width="100%")
    .gradient__sidebar
      EcreativMoleculesSidebar(
        :links="links"
        title="Our Beliefs"
        background="transparent"
      )
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
    required: true,
  },
});

const tabs = ref([
  { label: 'Articles' },
  { label: 'Books' },
  { label: 'Arguments' },
]);

const selected = ref('Articles');

const setSelected = (tab) => {
  selected.value = tab;
};

const links = [
  {
    navTitle: 'Allah',
    dataLink: [
      {
        name: 'Who is God?',
        label: 'Who is God?',
        text: 'Who is God?',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'How Can We Know God?',
        label: 'How Can We Know God?',
        text: 'How Can We Know God?',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Prophets',
    dataLink: [
      {
        name: 'The Prophet Muhammad',
        label: 'The Prophet Muhammad',
        text: 'The Prophet Muhammad',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'The Promised Messiah',
        label: 'The Promised Messiah',
        text: 'The Promised Messiah',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Other Prophets',
        label: 'Other Prophets',
        text: 'Other Prophets',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Islam',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Further Learning',
        label: 'Further Learning',
        text: 'Further Learning',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Articles of Faith',
        label: 'Articles of Faith',
        text: 'Articles of Faith',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Pillars of Faith',
        label: 'Pillars of Faith',
        text: 'Pillars of Faith',
        link: '',
        type: 'IsInternal',
      },
      {
        name: "The Qur'an",
        label: "The Qur'an",
        text: "The Qur'an",
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Ahmadiyya',
    dataLink: [
      {
        name: 'Overview',
        label: 'Overview',
        text: 'Overview',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Promised Messiah in His Own Words',
        label: 'Promised Messiah in His Own Words',
        text: 'Promised Messiah in His Own Words',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Beliefs: In-Depth',
        label: 'Beliefs: In-Depth',
        text: 'Beliefs: In-Depth',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Khilafat',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Types of Khilafat',
        label: 'Types of Khilafat',
        text: 'Types of Khilafat',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Rashidun Khilafat',
        label: 'Rashidun Khilafat',
        text: 'Rashidun Khilafat',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Ahmadiyya Khilafat',
        label: 'Ahmadiyya Khilafat',
        text: 'Ahmadiyya Khilafat',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.gradient {
  padding: 160px 0;
  background: linear-gradient(
    to bottom,
    #3f2a89 0%,
    #55266c 35%,
    #6b214f 50%,
    #361128 85%,
    #000000 100%
  );
  color: white;
  font-family: Arial, sans-serif;
}

.gradient__grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 0px 1fr 0px;
  grid-template-areas:
    '. top .'
    '. main .'
    '. sidebar .';

  @include media-breakpoint-up(sm) {
    grid-template-columns: calc(50vw - 17.1rem) 1fr calc(50vw - 17.1rem);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: calc(50vw - 22rem) 1fr calc(50vw - 22rem);
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top .'
      '. main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar .';
    grid-template-rows: repeat(2, auto);
    grid-template-columns:
      calc(50vw - 30rem)
      repeat(24, 1fr)
      calc(50vw - 30rem);
  }

  @include media-breakpoint-up(xl) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top .'
      ' . main main main main main main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar . ';
    row-gap: 3rem;
    column-gap: 1rem;
    grid-template-columns:
      calc(50vw - 36rem) repeat(36, 1fr)
      calc(50vw - 36rem);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: calc(50vw - 40rem) repeat(36, 1fr) calc(50vw - 40rem);
  }
}

.gradient__top {
  grid-area: top;
}

.gradient__main {
  grid-area: main;
}

.gradient__sidebar {
  grid-area: sidebar;
}

.tabs {
  width: 100%;
  border-bottom: 2px solid #8c78aa;

  &__nav {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #bca9da;
    font-weight: bold;
  }

  .tab__item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: color 0.3s;

    &:hover {
      color: white;
    }

    &.active {
      color: white;
    }
  }
}

.articles-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr); // 3 columns for articles
  grid-auto-rows: auto; // Automatically adjust row height
  width: 100%;

  .load-more-container {
    grid-column: 1 / -1; // Span the button across all columns
    display: flex;
    justify-content: center; // Center the button horizontally
    margin-top: 1rem; // Add spacing from the grid items
  }
}
.books-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.arguments-grid {
  display: grid;
  gap: 1rem; // Space around the grid item
  grid-template-columns: 1fr; // Single column layout
  grid-auto-rows: auto; // Automatically adjust the row height

  .argument-item {
    grid-column: span 1; // Occupies the entire column
    width: 100%; // Ensures full width
    position: relative;
    overflow: hidden;
  }

  // Responsive adjustments (if needed)
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Same layout for smaller screens
  }
}
</style>
