<template lang="pug">
article.card.w-100.h-100.d-flex.rounded-4
  .w-100.text-decoration-none.d-flex.h-100(@click="navigateToArticle")
    .card__inner.w-100.d-flex.flex-column
      .card__image.ratio.ratio-16x9
        nuxt-img(
          v-if='image',
          :src="image"
          :alt='`Feature image of ${title}`',
          format='webp',
          loading="lazy"
          fit="cover",
          width='520',
          height='320',
          sizes='xs:95px md:110px lg:420px'
        )
      .card__content.h-100.gap-2.gap-md-3.d-flex.flex-column
        EcreativAtomsTime(
          :datetime="time"
          size="7"
          context="dark"
          fontWeight="normal"
          class="card-article__time"
        )
        EcreativAtomsHeading.d-flex.flex-grow-1(
          v-if='title',
          tag='h3'
          size="6"
        )
          span(v-html='title')
        EcreativAtomsLink.fs-7(
          linkType="IsInternal"
          :link="getUrl"
          context="white"
          linkColor='dark'
          class="card-article__link"
          text="Read more"
        )
</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import EcreativAtomsTime from '../../atoms/Time.vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsLink from '../../atoms/Link.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    default: '',
  },
  category: {
    type: String,
    default: '',
  },
  time: {
    type: String,
    default: '2023-01-10',
  },
});

const getUrl = computed(
  () => `/${props.category || 'uncategorized'}/${props.link || ''}`,
);

const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  flex-direction: column;
  background-color: $white;
  overflow: hidden;

  &__content {
    padding: 24px 24px 24px 24px;
  }
}
</style>
