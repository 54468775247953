import { computed } from 'vue';

interface IconProps {
  width: string | number;
  height: string | number;
  fontSize?: string;
  iconColor?: string;
}

const useIconSetUp = (props: IconProps) => {
  const widthValue = computed(() => `${props.width}`);
  const heightValue = computed(() => `${props.height}`);
  const viewBoxProp = computed(() => `0 0 ${props.width} ${props.height}`);

  const iconStyle = computed(() => {
    const { fontSize, width, iconColor } = props;

    return fontSize
      ? {
        fontSize: fontSize,
        color: iconColor,
      }
      : {
        fontSize: `${Number(width) / 16}rem`,
        color: iconColor,
      };
  });

  return {
    widthValue,
    heightValue,
    viewBoxProp,
    iconStyle,
  };
};

export default useIconSetUp;
