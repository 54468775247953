<template lang="pug">
.convertion.rounded-4(@click="navigateToArticle")
  nuxt-img(
    v-if="image",
    :src="image",
    :alt="`${title} ${subtitle}`",
    loading="lazy",
    fit="cover",
    width="100%",
    height="100%",

  )
  .card-info
    .d-flex.flex-column.justify-content-center.h-100
      EcreativAtomsHeading.pb-2(tag="h3", size="2" context="white" class="m-0") {{ title }}
      EcreativAtomsParagraph(class="m-0" context="white" style="max-width: 420px;") {{ subtitle }}
      .card-button
        EcreativAtomsButton(
          style="max-width: 200px;"
          buttonType="Link",
          linkType="IsInternal",
          :link="getUrl",
          context="info",
          class="fw-bold justify-content-center",
          textColor="white",
          :text="buttonText",
          :isBlock="true"
        )

</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsButton from '../../atoms/Button.vue';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  buttonText: {
    type: String,
  },
  link: {
    type: String,
    default: '',
  },
  category: {
    type: String,
    default: '',
  },
});
const getUrl = computed(() => `/${props.category}/${props.link}`);
const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.convertion {
  position: relative;
  width: 100%;
  height: 100%;
  // border-radius: $border-radius-lg;
  overflow: hidden;
  cursor: pointer;
}

.card-info {
  position: absolute;
  padding-left: 48px;
  top: 0;
  bottom: 0;
  height: 100%;
}

.card-button {
  padding-top: 25px;
}
</style>
