import { computed } from 'vue';
import { type MenuItemMolecule, ComponentType } from 'data-types-config';

import IsInput from '../components/atoms/Links/IsInput.vue';
import IsInternal from '../components/atoms/Links/IsInternal.vue';
import IsExternal from '../components/atoms/Links/IsExternal.vue';
import IsButton from '../components/atoms/Links/IsButton.vue';
import IsFake from '../components/atoms/Links/IsFake.vue';
import IsEmail from '../components/atoms/Links/IsEmail.vue';
import IsTelephone from '../components/atoms/Links/IsTelephone.vue';

type TextDecorationKey = 'underline' | 'none' | 'onHover';

export default function useLinkLogic(props: Partial<MenuItemMolecule>) {
    const typesMap = {
        IsEmail,
        IsExternal,
        IsFake,
        IsInput,
        IsInternal,
        IsTelephone,
        IsButton,
    };

    const isType = computed(() => {
        const typeKey = props.componentType as ComponentType;
        return typesMap[typeKey] || null;
    });

    const allClasses = computed(() => {
        // Determine text decoration class
        const underlineMap: Record<TextDecorationKey, string> = {
            underline: 'text-decoration-underline',
            none: 'text-decoration-none',
            onHover: 'text-decoration-hover',
        };

        // Directly use props.isUnderLine as TextDecorationKey, with a fallback to 'underline'
        const textDecorationKey: TextDecorationKey =
            props.isUnderLine || 'underline';

        const textDecorationClass = underlineMap[textDecorationKey];

        // Compile all classes, including additional and conditional ones
        const additionalClasses = [
            `btn-${props.context}`,
            `btn-${props.size}`,
            `link-${props.linkColor}`,
            props.fontWeight ? `fw-${props.fontWeight}` : '',
            props.visualStyle ? `link--${props.visualStyle}` : '',
            props.hoverColor ? `hasHover` : '',
            props.disabled ? 'link--disabled' : '',
        ].filter(Boolean);

        // Combine textDecorationClass, directionClass, classObjectStrings, and additionalClasses
        return [textDecorationClass, ...additionalClasses].join(' ');
    });

    const hoverColorBind = computed(() => {
        return props?.hoverColor?.startsWith('var:')
            ? props?.hoverColor?.replace(/^var:/, `var(--${'ti-'}`) + ')'
            : props.hoverColor;
    });

    return {
        allClasses,
        hoverColorBind,
        isType,
    };
}
