<template lang="pug">
nuxt-link.link.gap-2.align-items-center(
  :to="link"
  :aria-label="computedAriaLabel"
  :target="target"
  :class="allClasses"
)
  | {{ text || '' }}
  slot(name="icon")
</template>

<script setup lang="ts">
import { defineProps, withDefaults, computed } from 'vue';
import { type InternalLink, defaultInternalLink } from 'data-types-config';
import useStyleLogic from '../../../composables/useStyleLogic';

// Define props with default values
const props = withDefaults(defineProps<InternalLink>(), defaultInternalLink);

// Import style logic
const { allClasses, hoverColorBind } = useStyleLogic(props);

// Create a computed property for the aria-label
const computedAriaLabel = computed(() => {
  if (!props.text && !props.ariaLabel) {
    // If both are missing, you can throw a warning or provide a default
    console.warn(
      'Either `text` or `ariaLabel` must be provided for accessibility.',
    );
    return props.link;
  }

  // If text is missing, use ariaLabel; otherwise, use text
  return props.ariaLabel || props.text;
});
</script>

<style lang="scss" scoped>
.link {
  &.hasHover:hover {
    color: v-bind('hoverColorBind') !important;
  }
}
</style>
