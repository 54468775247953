<template lang="pug">
EcreativAtomsButton.dropDownToggle.d-flex.fw-bold.gap-2.text-capitalize.bg-white(
  :class='classes'
  link=""
  aria-haspopup='true',
  :aria-expanded='isActive',
  context="none"
  componentType="IsButton",
  :text="itemName",
  @click='emitToggle'
)
  template(#icon)
    EcreativAtomsSvgIcon.dropDownToggle__arrow(
      iconName='arrowDowns',
      iconSize='xs',
      :isCustom='true'
    )
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import EcreativAtomsSvgIcon from '../../atoms/SvgIcon.vue';
import EcreativAtomsButton from '../../atoms/Button.vue';

const props = defineProps({
  itemName: {
    default: '',
    type: String,
  },
  isActive: Boolean,
});

const classes = computed(() => [{ 'dropDownToggle--open': props.isActive }]);

const emit = defineEmits(['update:isActive']);

const emitToggle = () => {
  emit('update:isActive', !props.isActive);
};
</script>

<style lang="scss" scoped>
.dropDownToggle {
  $block: &;
  &#{$block} {
    position: relative;
    white-space: nowrap;
    &:hover {
      .dropDownToggle__arrow {
        color: $secondary;
      }
    }
  }

  #{$block}__arrow {
    color: $gray-300;
    transition:
      color 200ms ease-in,
      transform 200ms ease-in;
  }
}

.dropDownToggle {
  $block: &;

  &#{$block} {
    &--open {
      #{$block}__arrow {
        color: $secondary;
        transition: transform 250ms ease-out;
        transform: rotate(180deg);
        transform-origin: center;
      }
    }
  }
}
</style>
