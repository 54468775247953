<template lang="pug">
.request-call.rounded-4
  .title-box
    EcreativAtomsHeading(tag="h3" size="4" class="title fs-4") {{ title }}
  .inputs.d-flex.flex-column.gap-3.align-items-center
    form(@submit.prevent="submitForm" id="form" class="inputbox d-flex align-items-center")
      span
        svg.icon(focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24")
          path(d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z")
      input(v-model="inputValue" type="text" :placeholder="placeholder")
    .checkbox.d-flex.gap-1.justify-content-center
      EcreativAtomsButton(v-if="show === 'button'" label="Submit button" buttonType="IsButton" text="Submit" context="dark" class="text-white justify-content-center" @click="submitForm")
      div(v-if="show === 'checkbox'" class="checkbox d-flex gap-1")
        input(type="checkbox" id="save-location" v-model="saveLocation")
        label(for="save-location") Save my location for future use
</template>

<script lang="ts" setup>
import EcreativAtomsHeading from '../atoms/Heading.vue';
import EcreativAtomsButton from '../atoms/Button.vue';

import { ref } from 'vue';

const props = defineProps({
  title: {
    type: String,
  },
  show: {
    type: String,
  },
  placeholder: {
    type: String,
  },
});

const inputValue = ref('');
const saveLocation = ref(false);

const submitForm = (event) => {
  console.log('Form submitted with:', inputValue.value, saveLocation.value);
  // Aquí puedes manejar la lógica de envío del formulario
};
</script>

<style lang="scss" scoped>
.request-call {
  background-color: #ffffff;
  // border-radius: $border-radius-xl;
  color: black;
  min-height: 180px;
  width: 100%;
  padding-bottom: 14px;
}

.title-box {
  border-bottom: 1px solid $green-100;
  padding-left: 24px;
  padding-top: 10px;
}

.inputs {
  padding: 0 15px;
  padding-top: 20px;
}

.inputbox {
  border: 2px solid $green-100;
  border-radius: $border-radius-sm;
  height: 45px;
  padding-left: 10px;
  width: 90%;
}

.inputbox input[type='text'] {
  border: none;
  outline: none;
  width: 90%;

  &::placeholder {
    color: black;
  }
}

.icon {
  fill: $gray-600;
  height: 18px;
  width: 18px;
}

.button {
  border: none;
  border-radius: $border-radius-xl;
  cursor: pointer;
  outline: none;
  padding: 8px 70px;
  transition: background-color 0.3s ease;
  width: 90%;

  &:hover {
    background-color: $purple-700;
  }

  &:focus {
    outline: none;
  }
}

.checkbox {
  width: 90%;
}
</style>
