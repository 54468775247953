<template>
  <svg
    viewBox="0 0 96 96"
    :aria-labelledby="iconName"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    role="presentation"
    :width="widthValue"
    :height="heightValue"
    :fill="iconColor"
    :style="iconStyle"
    aria-hidden="true"
  >
    <g fill="inherit">
      <path d="M36 36h24v24H36V36Z" style="fill: none" />
      <path
        d="M18.88 10.67c.32 4.75 1.12 9.39 2.4 13.81l-6.4 6.4a78.899 78.899 0 0 1-4.05-20.21h8.05m52.59 64.11c4.53 1.28 9.17 2.08 13.87 2.4v7.95c-7.04-.48-13.81-1.87-20.27-4l6.4-6.35M24 0H5.33C2.4 0 0 2.4 0 5.33 0 55.41 40.59 96 90.67 96 93.6 96 96 93.6 96 90.67V72.06c0-2.93-2.4-5.33-5.33-5.33-6.61 0-13.07-1.07-19.04-3.04-.53-.19-1.09-.28-1.65-.27-1.39 0-2.72.53-3.79 1.55L54.46 76.7a80.773 80.773 0 0 1-35.15-35.15l11.73-11.73a5.347 5.347 0 0 0 1.33-5.44 60.64 60.64 0 0 1-3.04-19.04c0-2.93-2.4-5.33-5.33-5.33Z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from 'vue';
import useIconSetUp from './useIconSetUp';

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'IconDefault',
    },
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    fontSize: {
      type: String,
      default: '1rem',
    },
    width: {
      type: String,
      default: '96',
    },
    height: {
      type: String,
      default: '96',
    },
  },
  setup(props) {
    const { widthValue, heightValue, viewBoxProp, iconStyle } =
      useIconSetUp(props);

    return {
      widthValue,
      heightValue,
      viewBoxProp,
      iconStyle,
    };
  },
});
</script>
