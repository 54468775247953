<template lang="pug">
li.menuItem.nav-item
  component.nav-link.link(
    :is="isType"
    :="$props"
    :scrollTo="link.scrollTo || undefined"
    @click="handleClick($event, link)"
    :class="allClasses"
  )
</template>

<script lang="ts" setup>
import { defineProps, withDefaults, computed } from 'vue';
import {
  type MenuItemMolecule,
  ComponentType,
  defaultMenuItemMolecule,
} from 'data-types-config';

const modalStore = useModalStore();

function handleClick(event: MouseEvent, link: string): void {
  if (link === '#modalStore.openModal') {
    event.preventDefault();
    modalStore.openModal();
  }
}

const props = withDefaults(
  defineProps<Partial<MenuItemMolecule>>(),
  defaultMenuItemMolecule,
);

const { allClasses, hoverColorBind, isType } = useLinkLogic(props);
</script>

<style scoped lang="scss">
.menuItem {
  display: inline-flex;

  & :deep(.link--highlighted) {
    color: var(--ti-heading-color);
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    font-size: clamp(0.7rem, 2vw, 1rem);
    text-transform: uppercase;
  }

  & :deep(.link--underlined) {
    display: inline;
    color: var(--ti-heading-color);
    font-size: clamp(1.1rem, 2vw, 1.12rem);
    white-space: break-spaces;
    margin-bottom: 1px;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--ti-secondary);

    &:hover {
      margin-bottom: 0;
      border-bottom: 2px solid var(--ti-secondary);
    }
  }
}
</style>
