/**
 * Vue directive to handle navigation and scrolling behavior on click.
 *
 * This directive enables an element to navigate to a specific page and scroll to a target element
 * within that page. It supports smooth scrolling and customizable alignment options.
 *
 * @module directives/toggleDirective
 */

const toggleDirective = {
  /**
   * Hook called when the directive is bound to an element.
   *
   * @param {HTMLElement} el - The DOM element the directive is bound to.
   * @param {Object} binding - An object containing the directive's value and arguments.
   * @param {Object} binding.value - The value passed to the directive.
   * @param {string} binding.value.el - The selector for the target element to scroll to.
   * @param {string} [binding.value.page] - The URL pathname to navigate to before scrolling.
   * @param {string} [binding.value.easing='smooth'] - The scrolling behavior ('smooth' or 'auto').
   * @param {boolean} [binding.value.y=true] - Whether to align the target element vertically.
   * @param {boolean} [binding.value.x=false] - Whether to align the target element horizontally.
   *
   * @example
   * <!-- Scroll to a section on the current page -->
   * <button v-toggle="{ el: '#section1' }">Go to Section 1</button>
   *
   * @example
   * <!-- Navigate to another page and scroll to a section -->
   * <button v-toggle="{ el: '#section1', page: '/about', easing: 'auto' }">Go to About Section</button>
   */
  mounted(el, binding) {
    // Validate and extract options
    const options = binding.value || {};
    if (!options.el) {
      console.warn(
        'toggleDirective: "el" property is required in the binding value.',
      );
      return;
    }

    /**
     * Handles the click event to navigate and/or scroll.
     */
    const onClick = () => {
      const currentUrl = new URL(window.location);

      // Navigate to a new page if specified
      if (options.page) {
        currentUrl.pathname = options.page;
        currentUrl.hash = options.el;
        window.location.href = currentUrl.href;
        return;
      }

      // Scroll to the target element if it exists
      const targetElement = document.querySelector(options.el);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: options.easing || 'smooth',
          block: options.y ? 'start' : 'center',
          inline: options.x ? 'start' : 'center',
        });
      } else {
        console.warn(
          `toggleDirective: Target element not found for selector "${options.el}".`,
        );
      }
    };

    // Attach the click event listener
    el.addEventListener('click', onClick);

    // Store the listener for later removal
    el._toggleClickListener = onClick;
  },

  /**
   * Hook called before the element is unmounted, used for cleanup.
   *
   * @param {HTMLElement} el - The DOM element the directive is bound to.
   */
  beforeUnmount(el) {
    // Remove the stored click event listener
    if (el._toggleClickListener) {
      el.removeEventListener('click', el._toggleClickListener);
      delete el._toggleClickListener; // Clean up to prevent memory leaks
    }
  },
};

export default toggleDirective;
