<template lang="pug">
#lottie
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import lottie from 'lottie-web';
import loaderData from './loader.json';

// Props for flexibility
const props = defineProps({
  width: {
    type: String,
    default: '100px',
  },
  height: {
    type: String,
    default: '100px',
  },
  loop: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
});

// Animation instance reference
let animInstance = null;

onMounted(() => {
  const lottieContainer = document.getElementById('lottie');

  if (lottieContainer) {
    const params = {
      container: lottieContainer,
      renderer: 'svg',
      loop: props.loop,
      autoplay: props.autoplay,
      animationData: loaderData,
    };

    animInstance = lottie.loadAnimation(params);
    animInstance.setSpeed(4);
  } else {
    console.error('Lottie container not found!');
  }
});

// Cleanup animation instance on unmount
onUnmounted(() => {
  if (animInstance) {
    animInstance.destroy();
    animInstance = null;
  }
});
</script>

<style lang="scss" scoped>
#lottie {
  width: var(--lottie-width, 100px);
  height: var(--lottie-height, 100px);
}

/* Allow dynamic sizing through CSS variables */
:root {
  --lottie-width: 100px;
  --lottie-height: 100px;
}
</style>
