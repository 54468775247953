<template lang="pug">
article.card.w-100.h-100.d-flex
  .w-100.text-decoration-none.d-flex(@click="navigateToArticle")
    .card__inner.w-100.gap-2.gap-md-3.d-flex
      .card__image
        nuxt-img(
          v-if='image',
          :src='image',
          :alt='`Feature image of ${title}`',
          format='webp',
          loading='lazy',
          fit="cover",
          width='520',
          height='320',
          sizes='xs:95px md:110px lg:420px'
        )
      .card__content.h-100
        EcreativAtomsHeading.card__title(
          v-if='title',
          context='none',
          tag='h3'
          size='4'
        )
          span(v-html='title')
        EcreativAtomsHeading.card__date(
          v-if='date',
          context='none',
          tag='h6'
        ) {{ date }}
        EcreativAtomsParagraph.card__text(v-if='content' size="7" context="dark"  v-html='content')
        EcreativAtomsLinksIsInternal.card__button(
          :link="getUrl",
          :text='linkLabel',
          linkColor='dark',
          fontWeight="bold",
          hoverColor="var:secondary"
          isUnderLine="none"
        )
          template(#icon)
            EcreativAtomsSvgIcon(
              iconName="arrowRights",
              isCustom,
              iconSize="sm",
            )
</template>
<script lang="ts" setup>
import { computed, defineProps, withDefaults } from 'vue';
import { type ArticleModel, defaultArticleModel } from 'data-types-config';
import EcreativAtomsSvgIcon from '../../atoms/SvgIcon.vue';
import EcreativAtomsButton from '../../atoms/Button.vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsParagraph from '../../atoms/Paragraph.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = withDefaults(defineProps<ArticleModel>(), {
  image: defaultArticleModel.image,
  link: defaultArticleModel.link,
  linkLabel: defaultArticleModel.linkLabel,
  title: defaultArticleModel.title,
  date: defaultArticleModel.date,
  content: defaultArticleModel.content,
  category: defaultArticleModel.category,
});

const getUrl = computed(
  () => `/${props.category || 'uncategorized'}/${props.link || ''}`,
);

const navigateToArticle = () => {
  router.push(getUrl.value);
};
</script>

<style lang="scss" scoped>
.card {
  container-type: inline-size;
  cursor: pointer;
}

.card__text {
  display: none;
}

.card__inner {
  flex-direction: row;
  align-items: stretch;
  background-color: $gray-100;
}

.card__title {
  flex: 1;
  margin-bottom: 0;
  font-size: 1rem;
}

.card__image {
  width: 95px;
}

.card__button {
  display: none;
}

.card__content {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
}

.card__date {
  display: none;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 0.9rem;
}

@container (min-width: 385px) {
  .card__content {
    padding: 16px 0px;
  }

  .card__image {
    width: 110px;
    height: 110px;
  }

  .card__title {
    margin-bottom: 8px;
    font-size: 1.2rem;
  }

  .card__text {
    margin-bottom: 16px;
  }
}

@container (min-width: 415px) {
  .card__inner {
    flex-direction: column;
    align-items: center;
  }

  .card__title {
    margin-bottom: 16px;
    font-size: 1.4rem;
  }

  .card__image {
    width: 100%;
    height: 235px;
  }

  .card__content {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 30px;
  }

  .card__button {
    display: flex;
  }

  .card__date {
    display: flex;
  }

  .card__text {
    display: flex;
    margin-bottom: 24px;
  }
}

@container (min-width: 470px) {
  .card__inner {
    align-items: flex-start;
    background-color: transparent;
  }

  .card__title {
    font-size: 1.6rem;
  }

  .card__content {
    width: 75%;
    padding: 0;
  }

  .card__image {
    height: 300px;
  }
}

@container (min-width: 500px) {
  .card__image {
    height: 320px;
  }
}
</style>
