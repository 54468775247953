<template lang="pug">
.ytp-list.videos-grid
  EcreativBaseVideoPlayer2.featured.w-100.d-flex.flex-column(data='Xmm62jEyDPs', title="Did Muslims Copy Hajj from Jews?!" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='cuP-EzP6a-Y', title='Prophecy in Bible, Hadith and QURAN Fulfilled! Truth of Ahmad (SERIES) – Part 6' @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='XsQzbIK221o', title="Message of Islam Ahmadiyya Examined: Truth of Ahmad (SERIES) – Part 7" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='FbVSPrKV7QI', title="AHMADI VS. SUNNI DEBATE! Adnan Rashid REFUSES to Debate Quran/Hadith" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='olHtV_FKvoY', title="How God Deals with Opponents of Islam Ahmadiyya" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='X0UM-Y51B_I', title="DEBATE | David Wood & Apostate Prophet VS Ahmadi Muslims – Was Muhammad a True Prophet?" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='ZaD_P8vv4To', title="Young Muslims REFUTE Christian & Atheist in Crucifixion Debate" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='lJo7Y709AR4', title="Sunni Shaykh Abandons Quran – For JESUS! Ahmadi Muslim DEBATES Sunni | Speaker's Corner Episode 2" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='N_sIetr5gII', title="The Sign of the Imam Mahdi NO-ONE Can Make up!" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='SmIWdXqzHMA', title="Christian vs Muslim DEBATE: Did Jesus Die by Crucifixion? Inspiring Philosophy vs True Islam UK" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='mQUZdansnS0', title="Bible Vs Quran – David Wood Vs Muslims on Preservation / Authenticity / TRUTH" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='wAEFDzlbifI', title="How a New York Banker Accepted Islam" @update-last-player='playerOnClick($event)')
  EcreativBaseVideoPlayer2.w-100.d-flex.flex-column(data='1j4OlXcQTYU', title="Secret of 'Seal of Prophets' Muslims Forgot! Truth of Ahmad (SERIES) – Part 5" @update-last-player='playerOnClick($event)')
</template>
<script>
/* eslint-disable */
import loadYouTubeIframeApi from '@/services/youtube/loadYouTubeIframeApi';

export default {
  name: 'VideoPlayList',
  data() {
    return {
      videos: [],
      nextPageToken: '',
      totalResults: 0,
      isLoading: false,
      queryElem: '',
      options: {
        prefill: true,
        append: true,
        checkLastPage: true,
      },
      newItems: '',
      status: '',
      canLoad: true,
      // API
      // players objects
      players: [],
      items: [],
      lastPlayerChanged: '',
    };
  },
  mounted() {
    const vm = this;
    vm.queryElem = vm.getQueryElement('.ytp-list');
    vm.element = vm.queryElem;
    vm.$nextTick(function () {
      vm.initPlayers();
      // vm.prefill()
      // 2. This code loads the IFrame Player API code asynchronously.
      if (!vm.youtubeIframeAPI) {
        vm.youtubeIframeAPI = loadYouTubeIframeApi();
      }
    });
  },
  methods: {
    getQueryElement(elem) {
      if (typeof elem === 'string') {
        return document.querySelector(elem);
      }
      return elem;
    },
    // API
    initPlayers() {
      const vm = this;
      // player elements
      vm.items = document.querySelectorAll('.ytp');

      for (let i = 0; i < vm.items.length; i += 1) {
        if (vm.items[i].id === '') {
          // add a unique id on the page to each player div
          vm.items[i].id = i;

          const classNames = vm.items[i].parentElement.className.split(' ');

          const parentClassName = classNames[0];

          vm.players[`player${i}`] = {
            id: vm.items[i].id,
            parentClass: parentClassName,
          };

          // thumbnail image source.
          const source = `https://img.youtube.com/vi/${vm.items[i].dataset.embed}/0.jpg`;

          // vm.items[i].style.backgroundImage = 'url(' + source + ')'
          const img = vm.items[i].querySelector('.card--youtube');
          img.style.backgroundImage = `url(${source})`;
        }
      }
    },
    playerOnClick(event) {
      const vm = this;
      const { currentTarget } = event;
      const targetId = currentTarget.id;

      if (currentTarget.querySelector(`#player${targetId}`)) {
        return;
      }

      // vm.lastPlayerChanged == targetId
      const card = currentTarget.querySelector('.card--youtube');
      card.classList.remove('overlay');
      card.innerHTML = '';
      const playerDiv = document.createElement('div');
      playerDiv.id = `player${targetId}`;
      card.appendChild(playerDiv);

      vm.players[`player${targetId}`].yt = new YT.Player(`player${targetId}`, {
        height: '100%',
        width: '100%',
        videoId: currentTarget.dataset.embed,
        playerVars: { rel: 0 },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });

      // 4. The API will call this function when the video player is ready.
      function onPlayerReady(event) {
        vm.onPlayerReady(event);
      }

      // 5. The API calls this function when the player's state changes.
      //    The function indicates that when playing a video (state=1),
      //    the vm.lastPlayerChanged variable will get player id
      function onPlayerStateChange(event) {
        vm.onPlayerStateChange(event);
      }
    },
    pauseVideo(id) {
      const vm = this;
      vm.players[id].yt.pauseVideo();
    },
    onPlayerReady(event) {
      const vm = this;

      event.target.playVideo();

      if (vm.lastPlayerChanged) {
        if (vm.lastPlayerChanged !== event.target.a.id) {
          vm.pauseVideo(vm.lastPlayerChanged);
        }
      }
    },
    onPlayerStateChange(event) {
      const vm = this;

      if (event.data === YT.PlayerState.UNSTARTED) {
        vm.lastPlayerChanged = event.target.a.id;
      }

      if (event.data === YT.PlayerState.PLAYING) {
        if (vm.lastPlayerChanged) {
          if (vm.lastPlayerChanged !== event.target.a.id) {
            vm.pauseVideo(vm.lastPlayerChanged);
          }
        }
        vm.lastPlayerChanged = event.target.a.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.featured {
  grid-column: span 2; // Span 2 columns
  grid-row: span 2; // Span 2 rows
  height: 100%; // Take full height for better visuals

  @media (max-width: 768px) {
    grid-column: span 4; // Span all columns on smaller screens
    grid-row: span 1; // Single row
  }
}

.videos-grid {
  display: grid;
  gap: 1rem; // Space between grid items
  grid-template-columns: repeat(3, 1fr); // 4 equal columns for smaller videos
  grid-auto-rows: auto; // Automatic height for rows

  .video-item {
    position: relative;

    &.featured {
      grid-column: span 2; // Span 2 columns
      grid-row: span 2; // Span 2 rows
      height: 100%; // Take full height for better visuals

      @media (max-width: 768px) {
        grid-column: span 4; // Span all columns on smaller screens
        grid-row: span 1; // Single row
      }
    }

    &:not(.featured) {
      height: 100%; // Ensure proper height for smaller videos
    }
  }
}
</style>
