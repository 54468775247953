import { computed } from 'vue';
import { type InternalLink } from 'data-types-config';

type TextDecorationKey = 'underline' | 'none' | 'onHover';

export default function useLinkLogic(props: Partial<InternalLink>) {
  const allClasses = computed(() => {
    // Determine text decoration class
    const underlineMap: Record<TextDecorationKey, string> = {
      underline: 'text-decoration-underline',
      none: 'text-decoration-none',
      onHover: 'text-decoration-hover',
    };

    // Directly use props.isUnderLine as TextDecorationKey, with a fallback to 'underline'
    const textDecorationKey: TextDecorationKey =
      props.isUnderLine || 'underline';

    const textDecorationClass = underlineMap[textDecorationKey];

    // Compile all classes, including additional and conditional ones
    const additionalClasses = [
      `link-${props.linkColor}`,
      props.fontWeight ? `fw-${props.fontWeight}` : '',
      props.hoverColor ? `hasHover` : '',
    ].filter(Boolean);

    // Combine textDecorationClass, directionClass, classObjectStrings, and additionalClasses
    return [textDecorationClass, ...additionalClasses].join(' ');
  });

  const hoverColorBind = computed(() => {
    return props?.hoverColor?.startsWith('var:')
      ? props?.hoverColor?.replace(/^var:/, `var(--${'ti-'}`) + ')'
      : props.hoverColor;
  });

  return {
    allClasses,
    hoverColorBind,
  };
}
