<template lang="pug">
.loadMore
  transition(name='feedbacksign')
    EcreativAtomsButton.fw-bold.loadMore__button(
      v-if='!isLoading'
      componentType="IsFake"
      context='primary',
      linkColor='white',
      text='Load More Articles',
      size='lg',
      :isDisabled="isDisabled",
      @click.prevent="onClickHandler"
    )
      template(#icon)
        EcreativAtomsSvgIcon(
          iconName="chevronDown",
          isCustom,
          iconSize="full",
          isAnimated
        )
    .loadMore__loader(v-else)
      EcreativAtomsSpinner
</template>

<script setup>
import { defineProps } from 'vue';
import EcreativAtomsSpinner from '../../atoms/Spinner.vue';
import EcreativAtomsSvgIcon from '../../atoms/SvgIcon.vue';
import EcreativAtomsButton from '../../atoms/Button.vue';

const articlesStore = useArticlesStore();

const onClickHandler = async () => {
  // If a custom onClick function is provided, run it
  if (props.onClick && typeof props.onClick === 'function') {
    await props.onClick();
  } else {
    // Otherwise, call the store action to load more
    await articlesStore.loadMoreArticles();
  }
};

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  onClick: {
    type: Function,
    default: null,
  },
});
</script>

<style lang="scss" scoped>
.loadMore {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 50px;
}

.loadMore__loader {
  width: 40px;
  aspect-ratio: 1/1;
}

.loadMore__loader,
.loadMore__button {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feedbacksign-enter-active,
.feedbacksign-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.feedbacksign-enter-active {
  transition-delay: 0.1s;
}

.feedbacksign-enter,
.feedbacksign-leave-to {
  opacity: 0;
}
</style>
