import { defineStore, acceptHMRUpdate } from 'pinia';

export const useGlobalStore = defineStore('GlobalStore', {
  state: () => {
    return {
      isPageLoading: false,
      isModalOpen: false,
    };
  },
  actions: {
    TOGGLE_MODAL(isOpen) {
      this.isModalOpen = isOpen;
    },

    SET_LOADER(isLoading) {
      this.isPageLoading = isLoading;
    },

    OPEN_MODAL_ACTION() {
      this.TOGGLE_MODAL(true);
    },

    CLOSE_MODAL_ACTION() {
      this.TOGGLE_MODAL(false);
    },

    HIDE_LOADER() {
      this.SET_LOADER(false);
    },

    SHOW_LOADER() {
      this.SET_LOADER(true);
    },
  },
  getters: {
    getIsPageLoading: (state) => state.isPageLoading,
    getModalOpen: (state) => state.isModalOpen,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
}
