<template lang="pug">
section.my-5.py-5
  #join.scrollIntoView.gradient__grid
    .gradient__top
      EcreativMoleculesTitleSection.mb-5(
        title="Join"
        subtitle="Joining Ahmadiyya Islam: A Life-Changing Decision"
      )
    .gradient__main
      .tabs__content.h-100
        .d-flex.gap-3
          .articles.d-flex.flex-column.gap-3
            .subtitle.d-flex.flex-column.gap-0
              h2.text-subtitle.fs-1.m-0.text-white
                | Every Step Forward is a Step Together
              h2.text-subtitle.fs-1.m-0.coloring
                | in a journey of Unity, Growth, and Discovery,
            .primary-card
              EcreativMoleculesCardsCard2(
                image="/images/join/Block-one.jpeg"
                title="What is Bai’at?"
                subtitle="Discover the meaning and profound spiritual significance of Bai’at as a pledge of allegiance in Islam."
                buttonText="Learn More"
              )
            .secondBloc-card.d-flex.gap-3.w-100
              .item
                EcreativMoleculesCardsCard4(
                  image="/images/join/Block-two-A.jpeg"
                  title="Explore the conditions of Bai`at"
                  linkColor="dark"
                )
              .item
                EcreativMoleculesCardsCard1(
                  image="/images/join/Block-two-B.jpeg"
                  title="Want to Join the Ahmadiyya Muslim Community?"
                )
            .third-row.d-flex.gap-3
              EcreativMoleculesCardsCard5(
                image="/images/join/Block-three.jpeg"
                title="Repentance: The Purpose of Bai’at"
                subtitle="Learn how Bai’at enables spiritual transformation and repentance, fostering a deeper connection with God."
              )
              EcreativMoleculesFaqAbout(
                title="Expectations of Members"
                subtitle="Understand the high standards of piety, humility, and service expected of members, and how to stay steadfast in prayers and good deeds."
              )
            EcreativMoleculesThreeColumInfo(
              image="/images/join/Block-six.jpeg"
              title="Initiating the Bai’at Process"
              subtitle="Step-by-step guide"
              :items="items"
            )
    .gradient__sidebar
      EcreativMoleculesSidebar(
        title="Top Articles"
        background="transparent"
      )
        ul.items.p-0.list-unstyled
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail1.png"
              title="My Journey to Ahmadiyya Islam: A Personal Story"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail2.png"
              title="Life After Conversion:Changes, Challenges, and Rewards"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail3.png"
              title="The Turning Point: What Drew Me to Ahmadiyya Islam"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail4.png"
              title="Why Faith Matters: Exploring the Role of Belief in Our Lives"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail5.png"
              title="Questions to Consider Before Converting to Ahmadiyya Islam"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail6.png"
              title="The Impact of Faith: Personal Growth Stories from Converts"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail7.png"
              title="Exploring the Core Beliefs of Ahmadiyya Islam"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail8.png"
              title="From Sunni to Ahmadiyya Islam - Convert Story"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail9.png"
              title="Navigating Family Dynamics: When You're the Only Muslim in the Family"
            )
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
    required: true,
  },
});

const tabs = ref([
  { label: 'Articles' },
  { label: 'Books' },
  { label: 'Arguments' },
]);

const selected = ref('Articles');

const setSelected = (tab) => {
  selected.value = tab;
};

const items = ref([
  {
    subtitle: 'Express Interest ',
    text: 'Submit the expression of interest form to begin your journey and signity your wish to join the Ahmadiyya Muslim Community.',
    link: 'link',
  },
  {
    subtitle: 'Verification',
    text: 'Community representatives will review your details and may contact you to provide guidance and ensure you fully understand the process.',
    link: 'link',
  },
  {
    subtitle: 'Complete Application',
    text: 'After verification, complete the formal Baliat application. Upon approval, you will receive a welcome pack with resources to support your integration into the community.',
    link: 'link',
  },
]);

const faqs = ref([
  {
    text: 'What is the conversion process like?',
    link: 'link1',
  },
  {
    text: 'What are the fundamental beliefs?',
    link: 'link2',
  },
  {
    text: 'What if my family has a different religious background?',
    link: 'link3',
  },
  {
    text: 'Are ther specific practices or rituals',
    link: 'link4',
  },
  {
    text: 'What changes should I expect in my daily life after converting?',
    link: 'link5',
  },
  {
    text: 'Can I still hold onto some beliefs or practices from my previous religion?',
    link: 'link6',
  },
]);

const links = [
  {
    navTitle: 'Allah',
    dataLink: [
      {
        name: 'Who is God?',
        label: 'Who is God?',
        text: 'Who is God?',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'How Can We Know God?',
        label: 'How Can We Know God?',
        text: 'How Can We Know God?',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Prophets',
    dataLink: [
      {
        name: 'The Prophet Muhammad',
        label: 'The Prophet Muhammad',
        text: 'The Prophet Muhammad',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'The Promised Messiah',
        label: 'The Promised Messiah',
        text: 'The Promised Messiah',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Other Prophets',
        label: 'Other Prophets',
        text: 'Other Prophets',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Islam',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Further Learning',
        label: 'Further Learning',
        text: 'Further Learning',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Articles of Faith',
        label: 'Articles of Faith',
        text: 'Articles of Faith',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Pillars of Faith',
        label: 'Pillars of Faith',
        text: 'Pillars of Faith',
        link: '',
        type: 'IsInternal',
      },
      {
        name: "The Qur'an",
        label: "The Qur'an",
        text: "The Qur'an",
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Ahmadiyya',
    dataLink: [
      {
        name: 'Overview',
        label: 'Overview',
        text: 'Overview',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Promised Messiah in His Own Words',
        label: 'Promised Messiah in His Own Words',
        text: 'Promised Messiah in His Own Words',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Beliefs: In-Depth',
        label: 'Beliefs: In-Depth',
        text: 'Beliefs: In-Depth',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Khilafat',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Types of Khilafat',
        label: 'Types of Khilafat',
        text: 'Types of Khilafat',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Rashidun Khilafat',
        label: 'Rashidun Khilafat',
        text: 'Rashidun Khilafat',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Ahmadiyya Khilafat',
        label: 'Ahmadiyya Khilafat',
        text: 'Ahmadiyya Khilafat',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.gradient {
  padding: 160px 0;
  background: linear-gradient(
    to bottom,
    #3f2a89 0%,
    #55266c 35%,
    #6b214f 50%,
    #361128 85%,
    #000000 100%
  );
  color: white;
  font-family: Arial, sans-serif;
}

.gradient__grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 0px 1fr 0px;
  grid-template-areas:
    '. top .'
    '. main .'
    '. sidebar .';

  @include media-breakpoint-up(sm) {
    grid-template-columns: calc(50vw - 17.1rem) 1fr calc(50vw - 17.1rem);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: calc(50vw - 22rem) 1fr calc(50vw - 22rem);
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top .'
      '. main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar .';
    grid-template-rows: repeat(2, auto);
    grid-template-columns:
      calc(50vw - 30rem)
      repeat(24, 1fr)
      calc(50vw - 30rem);
  }

  @include media-breakpoint-up(xl) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top .'
      ' . main main main main main main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar . ';
    row-gap: 3rem;
    column-gap: 1rem;
    grid-template-columns:
      calc(50vw - 36rem) repeat(36, 1fr)
      calc(50vw - 36rem);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: calc(50vw - 40rem) repeat(36, 1fr) calc(50vw - 40rem);
  }
}

.gradient__top {
  grid-area: top;
}

.gradient__main {
  grid-area: main;
}

.gradient__sidebar {
  grid-area: sidebar;
}

.tabs {
  width: 100%;
  border-bottom: 2px solid #8c78aa;

  &__nav {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #bca9da;
    font-weight: bold;
  }

  .tab__item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: color 0.3s;

    &:hover {
      color: white;
    }

    &.active {
      color: white;
    }
  }
}

.articles-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr); // 3 columns for articles
  grid-auto-rows: auto; // Automatically adjust row height
  width: 100%;

  .load-more-container {
    grid-column: 1 / -1; // Span the button across all columns
    display: flex;
    justify-content: center; // Center the button horizontally
    margin-top: 1rem; // Add spacing from the grid items
  }
}
.books-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.arguments-grid {
  display: grid;
  gap: 1rem; // Space around the grid item
  grid-template-columns: 1fr; // Single column layout
  grid-auto-rows: auto; // Automatically adjust the row height

  .argument-item {
    grid-column: span 1; // Occupies the entire column
    width: 100%; // Ensures full width
    position: relative;
    overflow: hidden;
  }

  // Responsive adjustments (if needed)
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Same layout for smaller screens
  }
}

.items-box {
  gap: 20px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: column;
  }
}

.item {
  padding-top: 24px;
  padding-bottom: 24px;

  + .item {
    border-top: 2px solid hsl(212, 100%, 79%, 0.5);
  }

  @include media-breakpoint-up(sm) {
    width: 33.3%;
  }

  @include media-breakpoint-up(xl) {
    width: unset;
  }
}

.title {
  padding-bottom: 28px;
}

.join-section {
  width: 1400px;
}

.head {
  padding-bottom: 20px;
}

.primary-card {
  height: 238px;
}

.primary-card {
  height: 238px;
}

.articles {
  flex: 1;
}

.secondBloc-card {
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.third-row {
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.right-section {
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(xl) {
    width: 25%;
  }
}

.coloring {
  color: #336699;
}

.subtitle {
  text-align: center;

  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}

.item {
  flex: 1;
}

.third-row {
  max-height: fit-content;
}

.threeColuminfo {
  height: 600px;
}

.left-article {
  width: 100%;
}
</style>
